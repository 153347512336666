.section.section--services {
  margin-top: 1rem;
  @media #{$breakpoint-md-up} {
    margin-top: 3rem;
  }
}
.section.section--guarantee {
  margin-top: 0;
  @media #{$breakpoint-md-up} {
    margin-top: 3rem;
  }
}

.guarantee {
  margin-top: 1.5rem;

  &__hero {
    position: relative;
  }

  &__hero-img {
    padding-bottom: 4.5rem;

    @media #{$breakpoint-md-up} {
      padding-bottom: 7.5rem;
      margin-bottom: -1rem;
    }
  }

  &__balloon {
    position: absolute;
    bottom: 0;

    &--desktop {
      width: 90%;
      left: -25%;
      display: none;

      @media #{$breakpoint-md-up} {
        display: block;
      }
    }

    &--mobile {
      width: 95%;
      right: -7%;
      @media #{$breakpoint-md-up} {
        display: none;
      }
    }
  }
}


.collaborators {

  &__heading {
    width: calc(135% + #{$container-pad--mobile}*2);
    margin-left: -$container-pad--mobile;
    margin-right: -$container-pad--mobile;
    margin-bottom: 1rem;

    @media #{$breakpoint-md-up} {
      width: calc(100% + #{$container-pad}*2);
      margin-left: -$container-pad;
      margin-right: -$container-pad;
    }

    text {
      letter-spacing: 0.1em;
      text-shadow: $color-cyan 0px 0px 0.8rem;
    }
  }

  &__collaborator:not(:last-child) {
    margin-bottom: 0.4rem;
    @media #{$breakpoint-md-up} {
      margin-bottom: 0;
    }
  }
}
