html {
  --plyr-range-track-height: 0.5rem;
  --plyr-color-main: black;
  --plyr-progress-loading-background: $color-green-dark;
  --plyr-video-progress-buffered-background: transparent;
  --plyr-audio-progress-buffered-background: transparent;

  --progress-video: 0;

  @media #{$breakpoint-md-up} {
    --plyr-range-track-height: 1rem;
  }
}


.plyr {
  display: flex;
  flex-direction: column;
}

.plyr--video .plyr__controls {
  background: none;
  justify-content: space-between;
  transform: none !important;
  opacity: 1 !important;
  position: relative;
  width: 100%;
  padding: 0;
  order: 2;
  margin-top: -1px;

  > * {
    margin: 0;
    padding: 0.2rem 0;
    height: 2rem;

    @media #{$breakpoint-md-up} {
      padding: 0;
      height: 2.2rem;
    }
  }

  .plyr__control {
    background: $color-green-dark;
    overflow: hidden;

    svg {
      height: 100%;
      width: auto;
      object-fit: contain;
      object-position: center;

      * {
        fill: transparent;
      }
    }
  }

  .plyr__control:hover {
    @media #{$breakpoint-md-up} {
      svg {
        .fill-hover {
          fill: $color-yellow !important;
        }
        .stroke-hover {
          stroke: $color-yellow !important;
        }
      }
    }
  }

  [data-plyr="play"] {
    border: 1px solid $color-yellow;
    border-radius: $border-radius--mobile;
    margin-right: -1px;
    min-width: 2rem;

    @media #{$breakpoint-md-up} {
      min-width: 3.5rem;
      border-radius: $border-radius;
    }

    svg {
      margin-left: auto;
      margin-right: auto;
    }
  }

  .plyr__time {
    background: $color-green-dark;
    overflow: hidden;
    border-top: 1px solid $color-yellow;
    border-bottom: 1px solid $color-yellow;
    display: flex;
    align-items: center;
    padding-top: 0.2rem;
    height: 2rem;
    font-size: $player-text-size--mobile;
    font-weight: bold;
    color: $color-green;

    @media #{$breakpoint-md-up} {
      height: 2.2rem;
      font-size: $player-text-size;
    }
  }

  .plyr__time--current {
    border-left: 1px solid $color-yellow;
    border-radius: $border-radius--mobile;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0px;
    padding-left: 0.5rem;

    @media #{$breakpoint-md-up} {
      padding-left: 0.8rem;
      border-radius: $border-radius;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .plyr__progress__container {
    background: $color-green-dark;
    display: flex;
    align-items: center;
    border-right: 0px;
    border-top: 1px solid $color-yellow;
    border-bottom: 1px solid $color-yellow;
    padding-left: 0.2rem;
    padding-right: 0.2rem;

    @media #{$breakpoint-md-up} {
      padding-left: 0.6rem;
      padding-right: 0.6rem;
    }

    .plyr__progress {
      flex-grow: 1;

      &, & * {
        cursor: pointer;
      }
    }
  }

  .plyr__volume {
    align-items: initial;
    border-top: 1px solid $color-yellow;
    border-bottom: 1px solid $color-yellow;
    padding: 0;

    button {
      padding: 0;
      width: 100%;
      padding: 0.2rem 0;

      @media #{$breakpoint-md-up} {
        padding: 0;
      }
    }
  }


  [data-plyr="fullscreen"] {
    border: 1px solid $color-yellow;
    border-radius: $border-radius--mobile;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: 0px;
    padding-right: 0.3rem;

    @media #{$breakpoint-md-up} {
      border-radius: $border-radius;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }
}

.plyr--full-ui.plyr--audio,
.plyr--full-ui.plyr--video {
  input[type="range"] {
    background-image: linear-gradient(90deg, $color-blue 0%, $color-green 80%);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    color: transparent;
    opacity: 0.3;
    height: 0.7rem;

    @media #{$breakpoint-md-up} {
      height: 1rem;
    }
  }
}

.plyr__poster {
  border: 1px solid;
  border-radius: $border-radius--mobile;
  overflow: hidden;

  @media #{$breakpoint-md-up} {
    border-radius: $border-radius;
  }
}

.plyr__video-wrapper {
  border-radius: $border-radius--mobile;
  overflow: hidden;

  @media #{$breakpoint-md-up} {
    border-radius: $border-radius;
  }
}

.video-progress-pointer {
  position: absolute;
  height: auto;
  width: 2.4rem;
  top: -0.8rem;
  margin-left: -1.2rem;
  left: var(--progress-video);
  z-index: 2;
  pointer-events: none;

  @media #{$breakpoint-md-up} {
    width: 3rem;
    top: -0.95rem;
    margin-left: -1.5rem;
  }

  .plyr__progress__container:hover & {
    @media #{$breakpoint-md-up} {
      svg {
        .fill-hover {
          fill: $color-green !important;
        }
        .stroke-hover {
          stroke: $color-green !important;
        }
      }
    }
  }
}
