.big-button {
  text-transform: uppercase;
  letter-spacing: 0.08rem;
  font-size: $big-button-text-size--mobile;
  border: 1px solid;
  border-radius: $border-radius;
  width: 100%;
  padding: 1.1rem 1.5rem 0;
  line-height: .85;
  color: $color-yellow;
  text-decoration: none;
  display: block;
  text-align: center;
  user-select: none;

  @media #{$breakpoint-md-up} {
    border-radius: $border-radius--mobile;
    padding: 1.5rem 1.5rem 0;
    line-height: .7;
    font-size: $big-button-text-size;
  }

  &:hover {
    color: $color-green;
  }
}
