.ticker {
  position: relative;
  width: 100%;
  overflow: hidden;
  display: block;
  transform: translateZ(0);
  height: $ticker-text-size--mobile;

  @media #{$breakpoint-md-up} {
    height: $ticker-text-size;
  }

  &--copyright {
    height: $copyright-text-size--mobile;

    @media #{$breakpoint-md-up} {
      height: $copyright-text-size;
    }
  }

  &__content {
    position: absolute;
    font-size: 0; //Remove extra spaces between inline-block divs
    display: flex;
    align-items: flex-start;
  }

  &__text {
    display: inline-block;
    display: flex;
    align-items: flex-start;

    > * {
      width: max-content;
      padding: 0 0.15em;
    }

    &--move {
      animation: ticker-move 18s linear infinite 0s;
      will-change: transform;

      .ticker--right & {
        animation-direction: reverse;
      }
    }

    span span {
      position: relative;
    }
  }

}
