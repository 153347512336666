html, body {
  min-height: 100%;
}

body {
  padding: 0;
  margin: 0;
}

button {
  border: 0;
  outline: 0;
  background: none;
  text-decoration: none;
}

a:not(.link--no-style) {
  color: inherit;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

figure {
  margin: 0;
}
