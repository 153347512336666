.case-studies {
  .section--case-studies {
    margin-top: 2rem;
    min-height: 70vh;

    @media #{$breakpoint-md-up} {
      margin-top: 3.5rem;
    }

    .row + .row {
      margin-top: 1rem;
    }
  }
}

.case-study-filters {
  z-index: 2;
  text-align: center;

  &, & * {
    color: $color-green;
    @extend .body-text--variant;
  }

  .case-studies & {
    border-top: 1px solid;
    border-bottom: 1px solid;
    width: calc(100% + #{$container-pad--mobile}*2);
    margin-left: -$container-pad--mobile;
    margin-right: -$container-pad--mobile;
    padding-bottom: $container-pad--mobile/2;
    padding-top: $container-pad--mobile/2 * 1.3;

    @media #{$breakpoint-md-up} {
      border-top: 0;
      border-bottom: 0;
      width: 100%;
      margin-left: 0;
      margin-right: 0;
      padding: 0;
    }
  }

  &__text-filter {
    display: none;
    @media #{$breakpoint-md-up} {
      display: initial;
    }
  }

  &__filter {
    position: relative;
    padding-left: 1em;
    margin-left: 0.5em;

    &:before {
      content: '';
      position: absolute;
      top: -0.4em;
      left: -0.4em;
      width: 1.6em;
      height: 1.6em;
      background: url('../img/graphics/high-dive-star.svg') no-repeat left top;
    }

    &.filter-case-studies--active {
      color: $color-yellow;
    }

    @media #{$breakpoint-md-up} {
      &:hover {
        cursor: pointer;
        color: $color-yellow;
      }
    }
  }
}

.floating-filters-trigger {
  width: 100%;
  height: 1px;
  background: transparent;
}

.floating-filters {
  position: fixed;
  z-index: 17;
  top: $container-pad--mobile;
  left: $container-pad--mobile;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease-out;
  display: none;

  @media #{$breakpoint-md-up} {
    top: $container-pad;
    left: $container-pad;
    display: initial;
  }

  .show-floating-filters & {
    opacity: 1;
    pointer-events: all;
  }

  &__filter {
    position: relative;
    padding-left: 1.3em;
    color: $color-green;
    @extend .body-text--floating-filters;

    .link-wave-underline {
      bottom: -3px;
    }

    &:before {
      content: '';
      position: absolute;
      top: -0.3em;
      left: -0.2em;
      width: 1.6em;
      height: 1.6em;
      background: url('../img/graphics/high-dive-star.svg') no-repeat left top;
    }

    &:hover {
      cursor: pointer;
      color: $color-yellow;
    }

    &.filter-case-studies--active {
      color: $color-yellow;
    }
  }
}
