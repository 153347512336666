.copyright {
  padding-top: 2.5rem;
  padding-bottom: 0.5rem;
  width: calc(100% + #{$container-pad--mobile}*2);
  margin-left: -$container-pad--mobile;
  margin-right: -$container-pad--mobile;

  @media #{$breakpoint-md-up} {
  padding-top: 4rem;
    width: calc(100% + #{$container-pad}*2);
    margin-left: -$container-pad;
    margin-right: -$container-pad;
  }
}

.footer {
  position: relative;
  z-index: 2;

  &__ticker-links {
    background: linear-gradient(180deg, $color-green-dark 0%, $color-blue 50%, $color-green-dark 100%);
  }

  &__big-ticker.ticker {
    border-top: 1px solid $color-green;
    padding-top: 0.65rem;
    height: calc(#{$ticker-text-size--mobile} * 0.82 + 0.65rem);

    @media #{$breakpoint-md-up} {
      height: calc(#{$ticker-text-size} * 0.82 + 1rem);
    }
  }

  &__big-ticker--variant.ticker {
    padding-top: 0;
    padding-bottom: 1rem;
    height: calc(#{$ticker-text-size--mobile} * 0.76 + 1rem);

    @media #{$breakpoint-md-up} {
      height: calc(#{$ticker-text-size} * 0.82 + 1rem);
    }
  }

  .ticker-text, .ticker-text * {
    transition: text-shadow 0.2s ease-out;
  }

  a.ticker:hover {
    .ticker-text, .ticker-text * {
      text-shadow: $color-yellow 0px 0px 1rem;

      &.text--green {
        text-shadow: $color-green 0px 0px 1rem;
      }

      &.text--green-dark {
        text-shadow: $color-green-dark 0px 0px 1rem;
      }

      &.text--blue {
        text-shadow: $color-blue 0px 0px 1rem;
      }

      &.text--cyan {
        text-shadow: $color-cyan 0px 0px 1rem;
      }
    }

    .ticker__image img {
      box-shadow: 0 0 1rem 0.25rem $color-green;
    }
  }


  .ticker__image {
    width: auto;
    display: inline-block;
    height: calc(#{$ticker-text-size--mobile} * 0.8 - 0.5rem);

    @media #{$breakpoint-md-up} {
      height: calc(#{$ticker-text-size} * 0.82 - 1rem);
    }

    img {
      height: 100%;
      width: auto;
      border-color: $color-green;
      box-shadow: 0 0 0.3rem 0.1rem $color-green;
      transition: box-shadow 0.2s ease-out;
    }
  }

  .footer__big-ticker--variant .ticker__image {
    margin-top: 0.5rem;

    @media #{$breakpoint-md-up} {
      margin-top: 1rem;
    }
  }
}
