.credits-line {
  display: flex;
  @extend .body-text--variant;

  &, & * {
    line-height: 1.3;
  }

  &__text-left,
  &__text-right {
    flex: 1 0 auto;
    color: $color-green;
  }
  &__dots {
    flex: 0 1 auto;
    margin: 0 5px;
    overflow: hidden;

    &:before {
      display: block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: clip;
      color: $color-blue;
      content:
       "...................."
       "...................."
       "...................."
       "....................";
     }
  }
}
