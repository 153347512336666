*, :before, :after {
  box-sizing: border-box;
  background-repeat: no-repeat;
}

:before, :after {
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
  vertical-align: inherit;
}

:where(:root) {
  cursor: default;
  overflow-wrap: break-word;
  tab-size: 4;
  -webkit-tap-highlight-color: transparent;
  -webkit-text-size-adjust: 100%;
  line-height: 1.5;
}

:where(body) {
  margin: 0;
}

:where(h1) {
  margin: .67em 0;
  font-size: 2em;
}

:where(dl, ol, ul) :where(dl, ol, ul) {
  margin: 0;
}

:where(hr) {
  color: inherit;
  height: 0;
}

:where(nav) :where(ol, ul) {
  padding: 0;
  list-style-type: none;
}

:where(nav li):before {
  content: "​";
  float: left;
}

:where(pre) {
  font-family: monospace;
  font-size: 1em;
  overflow: auto;
}

:where(abbr[title]) {
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

:where(b, strong) {
  font-weight: bolder;
}

:where(code, kbd, samp) {
  font-family: monospace;
  font-size: 1em;
}

:where(small) {
  font-size: 80%;
}

:where(audio, canvas, iframe, img, svg, video) {
  vertical-align: middle;
}

:where(iframe) {
  border-style: none;
}

:where(svg:not([fill])) {
  fill: currentColor;
}

:where(table) {
  border-collapse: collapse;
  border-color: inherit;
  text-indent: 0;
}

:where(button, input, select) {
  margin: 0;
}

:where(button, [type="button" i], [type="reset" i], [type="submit" i]) {
  -webkit-appearance: button;
}

:where(fieldset) {
  border: 1px solid #a0a0a0;
}

:where(progress) {
  vertical-align: baseline;
}

:where(textarea) {
  resize: vertical;
  margin: 0;
}

:where([type="search" i]) {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

:is(::-webkit-inner-spin-button, ::-webkit-outer-spin-button) {
  height: auto;
}

::-webkit-input-placeholder {
  color: inherit;
  opacity: .54;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

:where(dialog) {
  color: #000;
  background-color: #fff;
  border: solid;
  width: -moz-fit-content;
  width: fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: auto;
  padding: 1em;
  position: absolute;
  left: 0;
  right: 0;
}

:where(dialog:not([open])) {
  display: none;
}

:where(details > summary:first-of-type) {
  display: list-item;
}

:where([aria-busy="true" i]) {
  cursor: progress;
}

:where([aria-controls]) {
  cursor: pointer;
}

:where([aria-disabled="true" i], [disabled]) {
  cursor: not-allowed;
}

:where([aria-hidden="false" i][hidden]) {
  display: initial;
}

:where([aria-hidden="false" i][hidden]:not(:focus)) {
  clip: rect(0, 0, 0, 0);
  position: absolute;
}

@font-face {
  font-family: ABC Camera;
  src: url("ABCCamera-Black-Trial.8f165677.otf") format("opentype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: ABC Camera Plain;
  src: url("ABCCameraPlain-Black-Trial.9554c393.otf") format("opentype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: Relaate;
  src: url("Relaate0.5-SerifNarrow.63f9af8d.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: ITC Serif Gothic Std;
  src: url("SerifGothicStd-Light.ccbe4d00.otf") format("opentype");
  font-weight: normal;
  font-style: normal;
}

.row {
  box-sizing: border-box;
  flex-flow: wrap;
  flex: 0 auto;
  width: 100%;
  margin: 0 auto;
  display: flex;
}

.row.reverse {
  flex-direction: row-reverse;
}

.row.natural-height {
  align-items: flex-start;
}

.row.flex-column {
  flex-direction: column;
}

.col {
  box-sizing: border-box;
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
  padding: 0;
}

.col.reverse {
  flex-direction: column-reverse;
}

.first {
  order: -1;
}

.last {
  order: 1;
}

.align-start {
  align-self: flex-start;
}

.align-end {
  align-self: flex-end;
}

.align-center {
  align-self: center;
}

.align-baseline {
  align-self: baseline;
}

.align-stretch {
  align-self: stretch;
}

.col-xs {
  box-sizing: border-box;
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
  padding: 0;
}

.col-xs-1 {
  box-sizing: border-box;
  flex-basis: 4.16667%;
  max-width: 4.16667%;
  padding: 0;
}

.col-xs-offset-1 {
  margin-left: 4.16667%;
}

.col-xs-2 {
  box-sizing: border-box;
  flex-basis: 8.33333%;
  max-width: 8.33333%;
  padding: 0;
}

.col-xs-offset-2 {
  margin-left: 8.33333%;
}

.col-xs-3 {
  box-sizing: border-box;
  flex-basis: 12.5%;
  max-width: 12.5%;
  padding: 0;
}

.col-xs-offset-3 {
  margin-left: 12.5%;
}

.col-xs-4 {
  box-sizing: border-box;
  flex-basis: 16.6667%;
  max-width: 16.6667%;
  padding: 0;
}

.col-xs-offset-4 {
  margin-left: 16.6667%;
}

.col-xs-5 {
  box-sizing: border-box;
  flex-basis: 20.8333%;
  max-width: 20.8333%;
  padding: 0;
}

.col-xs-offset-5 {
  margin-left: 20.8333%;
}

.col-xs-6 {
  box-sizing: border-box;
  flex-basis: 25%;
  max-width: 25%;
  padding: 0;
}

.col-xs-offset-6 {
  margin-left: 25%;
}

.col-xs-7 {
  box-sizing: border-box;
  flex-basis: 29.1667%;
  max-width: 29.1667%;
  padding: 0;
}

.col-xs-offset-7 {
  margin-left: 29.1667%;
}

.col-xs-8 {
  box-sizing: border-box;
  flex-basis: 33.3333%;
  max-width: 33.3333%;
  padding: 0;
}

.col-xs-offset-8 {
  margin-left: 33.3333%;
}

.col-xs-9 {
  box-sizing: border-box;
  flex-basis: 37.5%;
  max-width: 37.5%;
  padding: 0;
}

.col-xs-offset-9 {
  margin-left: 37.5%;
}

.col-xs-10 {
  box-sizing: border-box;
  flex-basis: 41.6667%;
  max-width: 41.6667%;
  padding: 0;
}

.col-xs-offset-10 {
  margin-left: 41.6667%;
}

.col-xs-11 {
  box-sizing: border-box;
  flex-basis: 45.8333%;
  max-width: 45.8333%;
  padding: 0;
}

.col-xs-offset-11 {
  margin-left: 45.8333%;
}

.col-xs-12 {
  box-sizing: border-box;
  flex-basis: 50%;
  max-width: 50%;
  padding: 0;
}

.col-xs-offset-12 {
  margin-left: 50%;
}

.col-xs-13 {
  box-sizing: border-box;
  flex-basis: 54.1667%;
  max-width: 54.1667%;
  padding: 0;
}

.col-xs-offset-13 {
  margin-left: 54.1667%;
}

.col-xs-14 {
  box-sizing: border-box;
  flex-basis: 58.3333%;
  max-width: 58.3333%;
  padding: 0;
}

.col-xs-offset-14 {
  margin-left: 58.3333%;
}

.col-xs-15 {
  box-sizing: border-box;
  flex-basis: 62.5%;
  max-width: 62.5%;
  padding: 0;
}

.col-xs-offset-15 {
  margin-left: 62.5%;
}

.col-xs-16 {
  box-sizing: border-box;
  flex-basis: 66.6667%;
  max-width: 66.6667%;
  padding: 0;
}

.col-xs-offset-16 {
  margin-left: 66.6667%;
}

.col-xs-17 {
  box-sizing: border-box;
  flex-basis: 70.8333%;
  max-width: 70.8333%;
  padding: 0;
}

.col-xs-offset-17 {
  margin-left: 70.8333%;
}

.col-xs-18 {
  box-sizing: border-box;
  flex-basis: 75%;
  max-width: 75%;
  padding: 0;
}

.col-xs-offset-18 {
  margin-left: 75%;
}

.col-xs-19 {
  box-sizing: border-box;
  flex-basis: 79.1667%;
  max-width: 79.1667%;
  padding: 0;
}

.col-xs-offset-19 {
  margin-left: 79.1667%;
}

.col-xs-20 {
  box-sizing: border-box;
  flex-basis: 83.3333%;
  max-width: 83.3333%;
  padding: 0;
}

.col-xs-offset-20 {
  margin-left: 83.3333%;
}

.col-xs-21 {
  box-sizing: border-box;
  flex-basis: 87.5%;
  max-width: 87.5%;
  padding: 0;
}

.col-xs-offset-21 {
  margin-left: 87.5%;
}

.col-xs-22 {
  box-sizing: border-box;
  flex-basis: 91.6667%;
  max-width: 91.6667%;
  padding: 0;
}

.col-xs-offset-22 {
  margin-left: 91.6667%;
}

.col-xs-23 {
  box-sizing: border-box;
  flex-basis: 95.8333%;
  max-width: 95.8333%;
  padding: 0;
}

.col-xs-offset-23 {
  margin-left: 95.8333%;
}

.col-xs-24 {
  box-sizing: border-box;
  flex-basis: 100%;
  max-width: 100%;
  padding: 0;
}

.col-xs-offset-24 {
  margin-left: 100%;
}

.row.start-xs {
  justify-content: flex-start;
}

.row.center-xs {
  justify-content: center;
}

.row.end-xs {
  justify-content: flex-end;
}

.row.top-xs {
  align-items: flex-start;
}

.row.middle-xs {
  align-items: center;
}

.row.bottom-xs {
  align-items: flex-end;
}

.row.around-xs {
  justify-content: space-around;
}

.row.between-xs {
  justify-content: space-between;
}

.first-xs {
  order: -1;
}

.last-xs {
  order: 1;
}

@media only screen and (min-width: 641px) {
  .col-sm {
    box-sizing: border-box;
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
    padding: 0;
  }

  .col-sm-1 {
    box-sizing: border-box;
    flex-basis: 4.16667%;
    max-width: 4.16667%;
    padding: 0;
  }

  .col-sm-offset-1 {
    margin-left: 4.16667%;
  }

  .col-sm-2 {
    box-sizing: border-box;
    flex-basis: 8.33333%;
    max-width: 8.33333%;
    padding: 0;
  }

  .col-sm-offset-2 {
    margin-left: 8.33333%;
  }

  .col-sm-3 {
    box-sizing: border-box;
    flex-basis: 12.5%;
    max-width: 12.5%;
    padding: 0;
  }

  .col-sm-offset-3 {
    margin-left: 12.5%;
  }

  .col-sm-4 {
    box-sizing: border-box;
    flex-basis: 16.6667%;
    max-width: 16.6667%;
    padding: 0;
  }

  .col-sm-offset-4 {
    margin-left: 16.6667%;
  }

  .col-sm-5 {
    box-sizing: border-box;
    flex-basis: 20.8333%;
    max-width: 20.8333%;
    padding: 0;
  }

  .col-sm-offset-5 {
    margin-left: 20.8333%;
  }

  .col-sm-6 {
    box-sizing: border-box;
    flex-basis: 25%;
    max-width: 25%;
    padding: 0;
  }

  .col-sm-offset-6 {
    margin-left: 25%;
  }

  .col-sm-7 {
    box-sizing: border-box;
    flex-basis: 29.1667%;
    max-width: 29.1667%;
    padding: 0;
  }

  .col-sm-offset-7 {
    margin-left: 29.1667%;
  }

  .col-sm-8 {
    box-sizing: border-box;
    flex-basis: 33.3333%;
    max-width: 33.3333%;
    padding: 0;
  }

  .col-sm-offset-8 {
    margin-left: 33.3333%;
  }

  .col-sm-9 {
    box-sizing: border-box;
    flex-basis: 37.5%;
    max-width: 37.5%;
    padding: 0;
  }

  .col-sm-offset-9 {
    margin-left: 37.5%;
  }

  .col-sm-10 {
    box-sizing: border-box;
    flex-basis: 41.6667%;
    max-width: 41.6667%;
    padding: 0;
  }

  .col-sm-offset-10 {
    margin-left: 41.6667%;
  }

  .col-sm-11 {
    box-sizing: border-box;
    flex-basis: 45.8333%;
    max-width: 45.8333%;
    padding: 0;
  }

  .col-sm-offset-11 {
    margin-left: 45.8333%;
  }

  .col-sm-12 {
    box-sizing: border-box;
    flex-basis: 50%;
    max-width: 50%;
    padding: 0;
  }

  .col-sm-offset-12 {
    margin-left: 50%;
  }

  .col-sm-13 {
    box-sizing: border-box;
    flex-basis: 54.1667%;
    max-width: 54.1667%;
    padding: 0;
  }

  .col-sm-offset-13 {
    margin-left: 54.1667%;
  }

  .col-sm-14 {
    box-sizing: border-box;
    flex-basis: 58.3333%;
    max-width: 58.3333%;
    padding: 0;
  }

  .col-sm-offset-14 {
    margin-left: 58.3333%;
  }

  .col-sm-15 {
    box-sizing: border-box;
    flex-basis: 62.5%;
    max-width: 62.5%;
    padding: 0;
  }

  .col-sm-offset-15 {
    margin-left: 62.5%;
  }

  .col-sm-16 {
    box-sizing: border-box;
    flex-basis: 66.6667%;
    max-width: 66.6667%;
    padding: 0;
  }

  .col-sm-offset-16 {
    margin-left: 66.6667%;
  }

  .col-sm-17 {
    box-sizing: border-box;
    flex-basis: 70.8333%;
    max-width: 70.8333%;
    padding: 0;
  }

  .col-sm-offset-17 {
    margin-left: 70.8333%;
  }

  .col-sm-18 {
    box-sizing: border-box;
    flex-basis: 75%;
    max-width: 75%;
    padding: 0;
  }

  .col-sm-offset-18 {
    margin-left: 75%;
  }

  .col-sm-19 {
    box-sizing: border-box;
    flex-basis: 79.1667%;
    max-width: 79.1667%;
    padding: 0;
  }

  .col-sm-offset-19 {
    margin-left: 79.1667%;
  }

  .col-sm-20 {
    box-sizing: border-box;
    flex-basis: 83.3333%;
    max-width: 83.3333%;
    padding: 0;
  }

  .col-sm-offset-20 {
    margin-left: 83.3333%;
  }

  .col-sm-21 {
    box-sizing: border-box;
    flex-basis: 87.5%;
    max-width: 87.5%;
    padding: 0;
  }

  .col-sm-offset-21 {
    margin-left: 87.5%;
  }

  .col-sm-22 {
    box-sizing: border-box;
    flex-basis: 91.6667%;
    max-width: 91.6667%;
    padding: 0;
  }

  .col-sm-offset-22 {
    margin-left: 91.6667%;
  }

  .col-sm-23 {
    box-sizing: border-box;
    flex-basis: 95.8333%;
    max-width: 95.8333%;
    padding: 0;
  }

  .col-sm-offset-23 {
    margin-left: 95.8333%;
  }

  .col-sm-24 {
    box-sizing: border-box;
    flex-basis: 100%;
    max-width: 100%;
    padding: 0;
  }

  .col-sm-offset-24 {
    margin-left: 100%;
  }

  .row.start-sm {
    justify-content: flex-start;
  }

  .row.center-sm {
    justify-content: center;
  }

  .row.end-sm {
    justify-content: flex-end;
  }

  .row.top-sm {
    align-items: flex-start;
  }

  .row.middle-sm {
    align-items: center;
  }

  .row.bottom-sm {
    align-items: flex-end;
  }

  .row.around-sm {
    justify-content: space-around;
  }

  .row.between-sm {
    justify-content: space-between;
  }

  .first-sm {
    order: -1;
  }

  .last-sm {
    order: 1;
  }
}

@media only screen and (min-width: 1024px) {
  .col-md {
    box-sizing: border-box;
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
    padding: 0;
  }

  .col-md-1 {
    box-sizing: border-box;
    flex-basis: 4.16667%;
    max-width: 4.16667%;
    padding: 0;
  }

  .col-md-offset-1 {
    margin-left: 4.16667%;
  }

  .col-md-2 {
    box-sizing: border-box;
    flex-basis: 8.33333%;
    max-width: 8.33333%;
    padding: 0;
  }

  .col-md-offset-2 {
    margin-left: 8.33333%;
  }

  .col-md-3 {
    box-sizing: border-box;
    flex-basis: 12.5%;
    max-width: 12.5%;
    padding: 0;
  }

  .col-md-offset-3 {
    margin-left: 12.5%;
  }

  .col-md-4 {
    box-sizing: border-box;
    flex-basis: 16.6667%;
    max-width: 16.6667%;
    padding: 0;
  }

  .col-md-offset-4 {
    margin-left: 16.6667%;
  }

  .col-md-5 {
    box-sizing: border-box;
    flex-basis: 20.8333%;
    max-width: 20.8333%;
    padding: 0;
  }

  .col-md-offset-5 {
    margin-left: 20.8333%;
  }

  .col-md-6 {
    box-sizing: border-box;
    flex-basis: 25%;
    max-width: 25%;
    padding: 0;
  }

  .col-md-offset-6 {
    margin-left: 25%;
  }

  .col-md-7 {
    box-sizing: border-box;
    flex-basis: 29.1667%;
    max-width: 29.1667%;
    padding: 0;
  }

  .col-md-offset-7 {
    margin-left: 29.1667%;
  }

  .col-md-8 {
    box-sizing: border-box;
    flex-basis: 33.3333%;
    max-width: 33.3333%;
    padding: 0;
  }

  .col-md-offset-8 {
    margin-left: 33.3333%;
  }

  .col-md-9 {
    box-sizing: border-box;
    flex-basis: 37.5%;
    max-width: 37.5%;
    padding: 0;
  }

  .col-md-offset-9 {
    margin-left: 37.5%;
  }

  .col-md-10 {
    box-sizing: border-box;
    flex-basis: 41.6667%;
    max-width: 41.6667%;
    padding: 0;
  }

  .col-md-offset-10 {
    margin-left: 41.6667%;
  }

  .col-md-11 {
    box-sizing: border-box;
    flex-basis: 45.8333%;
    max-width: 45.8333%;
    padding: 0;
  }

  .col-md-offset-11 {
    margin-left: 45.8333%;
  }

  .col-md-12 {
    box-sizing: border-box;
    flex-basis: 50%;
    max-width: 50%;
    padding: 0;
  }

  .col-md-offset-12 {
    margin-left: 50%;
  }

  .col-md-13 {
    box-sizing: border-box;
    flex-basis: 54.1667%;
    max-width: 54.1667%;
    padding: 0;
  }

  .col-md-offset-13 {
    margin-left: 54.1667%;
  }

  .col-md-14 {
    box-sizing: border-box;
    flex-basis: 58.3333%;
    max-width: 58.3333%;
    padding: 0;
  }

  .col-md-offset-14 {
    margin-left: 58.3333%;
  }

  .col-md-15 {
    box-sizing: border-box;
    flex-basis: 62.5%;
    max-width: 62.5%;
    padding: 0;
  }

  .col-md-offset-15 {
    margin-left: 62.5%;
  }

  .col-md-16 {
    box-sizing: border-box;
    flex-basis: 66.6667%;
    max-width: 66.6667%;
    padding: 0;
  }

  .col-md-offset-16 {
    margin-left: 66.6667%;
  }

  .col-md-17 {
    box-sizing: border-box;
    flex-basis: 70.8333%;
    max-width: 70.8333%;
    padding: 0;
  }

  .col-md-offset-17 {
    margin-left: 70.8333%;
  }

  .col-md-18 {
    box-sizing: border-box;
    flex-basis: 75%;
    max-width: 75%;
    padding: 0;
  }

  .col-md-offset-18 {
    margin-left: 75%;
  }

  .col-md-19 {
    box-sizing: border-box;
    flex-basis: 79.1667%;
    max-width: 79.1667%;
    padding: 0;
  }

  .col-md-offset-19 {
    margin-left: 79.1667%;
  }

  .col-md-20 {
    box-sizing: border-box;
    flex-basis: 83.3333%;
    max-width: 83.3333%;
    padding: 0;
  }

  .col-md-offset-20 {
    margin-left: 83.3333%;
  }

  .col-md-21 {
    box-sizing: border-box;
    flex-basis: 87.5%;
    max-width: 87.5%;
    padding: 0;
  }

  .col-md-offset-21 {
    margin-left: 87.5%;
  }

  .col-md-22 {
    box-sizing: border-box;
    flex-basis: 91.6667%;
    max-width: 91.6667%;
    padding: 0;
  }

  .col-md-offset-22 {
    margin-left: 91.6667%;
  }

  .col-md-23 {
    box-sizing: border-box;
    flex-basis: 95.8333%;
    max-width: 95.8333%;
    padding: 0;
  }

  .col-md-offset-23 {
    margin-left: 95.8333%;
  }

  .col-md-24 {
    box-sizing: border-box;
    flex-basis: 100%;
    max-width: 100%;
    padding: 0;
  }

  .col-md-offset-24 {
    margin-left: 100%;
  }

  .row.start-md {
    justify-content: flex-start;
  }

  .row.center-md {
    justify-content: center;
  }

  .row.end-md {
    justify-content: flex-end;
  }

  .row.top-md {
    align-items: flex-start;
  }

  .row.middle-md {
    align-items: center;
  }

  .row.bottom-md {
    align-items: flex-end;
  }

  .row.around-md {
    justify-content: space-around;
  }

  .row.between-md {
    justify-content: space-between;
  }

  .first-md {
    order: -1;
  }

  .last-md {
    order: 1;
  }
}

@media only screen and (min-width: 1440px) {
  .col-lg {
    box-sizing: border-box;
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
    padding: 0;
  }

  .col-lg-1 {
    box-sizing: border-box;
    flex-basis: 4.16667%;
    max-width: 4.16667%;
    padding: 0;
  }

  .col-lg-offset-1 {
    margin-left: 4.16667%;
  }

  .col-lg-2 {
    box-sizing: border-box;
    flex-basis: 8.33333%;
    max-width: 8.33333%;
    padding: 0;
  }

  .col-lg-offset-2 {
    margin-left: 8.33333%;
  }

  .col-lg-3 {
    box-sizing: border-box;
    flex-basis: 12.5%;
    max-width: 12.5%;
    padding: 0;
  }

  .col-lg-offset-3 {
    margin-left: 12.5%;
  }

  .col-lg-4 {
    box-sizing: border-box;
    flex-basis: 16.6667%;
    max-width: 16.6667%;
    padding: 0;
  }

  .col-lg-offset-4 {
    margin-left: 16.6667%;
  }

  .col-lg-5 {
    box-sizing: border-box;
    flex-basis: 20.8333%;
    max-width: 20.8333%;
    padding: 0;
  }

  .col-lg-offset-5 {
    margin-left: 20.8333%;
  }

  .col-lg-6 {
    box-sizing: border-box;
    flex-basis: 25%;
    max-width: 25%;
    padding: 0;
  }

  .col-lg-offset-6 {
    margin-left: 25%;
  }

  .col-lg-7 {
    box-sizing: border-box;
    flex-basis: 29.1667%;
    max-width: 29.1667%;
    padding: 0;
  }

  .col-lg-offset-7 {
    margin-left: 29.1667%;
  }

  .col-lg-8 {
    box-sizing: border-box;
    flex-basis: 33.3333%;
    max-width: 33.3333%;
    padding: 0;
  }

  .col-lg-offset-8 {
    margin-left: 33.3333%;
  }

  .col-lg-9 {
    box-sizing: border-box;
    flex-basis: 37.5%;
    max-width: 37.5%;
    padding: 0;
  }

  .col-lg-offset-9 {
    margin-left: 37.5%;
  }

  .col-lg-10 {
    box-sizing: border-box;
    flex-basis: 41.6667%;
    max-width: 41.6667%;
    padding: 0;
  }

  .col-lg-offset-10 {
    margin-left: 41.6667%;
  }

  .col-lg-11 {
    box-sizing: border-box;
    flex-basis: 45.8333%;
    max-width: 45.8333%;
    padding: 0;
  }

  .col-lg-offset-11 {
    margin-left: 45.8333%;
  }

  .col-lg-12 {
    box-sizing: border-box;
    flex-basis: 50%;
    max-width: 50%;
    padding: 0;
  }

  .col-lg-offset-12 {
    margin-left: 50%;
  }

  .col-lg-13 {
    box-sizing: border-box;
    flex-basis: 54.1667%;
    max-width: 54.1667%;
    padding: 0;
  }

  .col-lg-offset-13 {
    margin-left: 54.1667%;
  }

  .col-lg-14 {
    box-sizing: border-box;
    flex-basis: 58.3333%;
    max-width: 58.3333%;
    padding: 0;
  }

  .col-lg-offset-14 {
    margin-left: 58.3333%;
  }

  .col-lg-15 {
    box-sizing: border-box;
    flex-basis: 62.5%;
    max-width: 62.5%;
    padding: 0;
  }

  .col-lg-offset-15 {
    margin-left: 62.5%;
  }

  .col-lg-16 {
    box-sizing: border-box;
    flex-basis: 66.6667%;
    max-width: 66.6667%;
    padding: 0;
  }

  .col-lg-offset-16 {
    margin-left: 66.6667%;
  }

  .col-lg-17 {
    box-sizing: border-box;
    flex-basis: 70.8333%;
    max-width: 70.8333%;
    padding: 0;
  }

  .col-lg-offset-17 {
    margin-left: 70.8333%;
  }

  .col-lg-18 {
    box-sizing: border-box;
    flex-basis: 75%;
    max-width: 75%;
    padding: 0;
  }

  .col-lg-offset-18 {
    margin-left: 75%;
  }

  .col-lg-19 {
    box-sizing: border-box;
    flex-basis: 79.1667%;
    max-width: 79.1667%;
    padding: 0;
  }

  .col-lg-offset-19 {
    margin-left: 79.1667%;
  }

  .col-lg-20 {
    box-sizing: border-box;
    flex-basis: 83.3333%;
    max-width: 83.3333%;
    padding: 0;
  }

  .col-lg-offset-20 {
    margin-left: 83.3333%;
  }

  .col-lg-21 {
    box-sizing: border-box;
    flex-basis: 87.5%;
    max-width: 87.5%;
    padding: 0;
  }

  .col-lg-offset-21 {
    margin-left: 87.5%;
  }

  .col-lg-22 {
    box-sizing: border-box;
    flex-basis: 91.6667%;
    max-width: 91.6667%;
    padding: 0;
  }

  .col-lg-offset-22 {
    margin-left: 91.6667%;
  }

  .col-lg-23 {
    box-sizing: border-box;
    flex-basis: 95.8333%;
    max-width: 95.8333%;
    padding: 0;
  }

  .col-lg-offset-23 {
    margin-left: 95.8333%;
  }

  .col-lg-24 {
    box-sizing: border-box;
    flex-basis: 100%;
    max-width: 100%;
    padding: 0;
  }

  .col-lg-offset-24 {
    margin-left: 100%;
  }

  .row.start-lg {
    justify-content: flex-start;
  }

  .row.center-lg {
    justify-content: center;
  }

  .row.end-lg {
    justify-content: flex-end;
  }

  .row.top-lg {
    align-items: flex-start;
  }

  .row.middle-lg {
    align-items: center;
  }

  .row.bottom-lg {
    align-items: flex-end;
  }

  .row.around-lg {
    justify-content: space-around;
  }

  .row.between-lg {
    justify-content: space-between;
  }

  .first-lg {
    order: -1;
  }

  .last-lg {
    order: 1;
  }
}

@media only screen and (min-width: 1791px) {
  .col-xl {
    box-sizing: border-box;
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
    padding: 0;
  }

  .col-xl-1 {
    box-sizing: border-box;
    flex-basis: 4.16667%;
    max-width: 4.16667%;
    padding: 0;
  }

  .col-xl-offset-1 {
    margin-left: 4.16667%;
  }

  .col-xl-2 {
    box-sizing: border-box;
    flex-basis: 8.33333%;
    max-width: 8.33333%;
    padding: 0;
  }

  .col-xl-offset-2 {
    margin-left: 8.33333%;
  }

  .col-xl-3 {
    box-sizing: border-box;
    flex-basis: 12.5%;
    max-width: 12.5%;
    padding: 0;
  }

  .col-xl-offset-3 {
    margin-left: 12.5%;
  }

  .col-xl-4 {
    box-sizing: border-box;
    flex-basis: 16.6667%;
    max-width: 16.6667%;
    padding: 0;
  }

  .col-xl-offset-4 {
    margin-left: 16.6667%;
  }

  .col-xl-5 {
    box-sizing: border-box;
    flex-basis: 20.8333%;
    max-width: 20.8333%;
    padding: 0;
  }

  .col-xl-offset-5 {
    margin-left: 20.8333%;
  }

  .col-xl-6 {
    box-sizing: border-box;
    flex-basis: 25%;
    max-width: 25%;
    padding: 0;
  }

  .col-xl-offset-6 {
    margin-left: 25%;
  }

  .col-xl-7 {
    box-sizing: border-box;
    flex-basis: 29.1667%;
    max-width: 29.1667%;
    padding: 0;
  }

  .col-xl-offset-7 {
    margin-left: 29.1667%;
  }

  .col-xl-8 {
    box-sizing: border-box;
    flex-basis: 33.3333%;
    max-width: 33.3333%;
    padding: 0;
  }

  .col-xl-offset-8 {
    margin-left: 33.3333%;
  }

  .col-xl-9 {
    box-sizing: border-box;
    flex-basis: 37.5%;
    max-width: 37.5%;
    padding: 0;
  }

  .col-xl-offset-9 {
    margin-left: 37.5%;
  }

  .col-xl-10 {
    box-sizing: border-box;
    flex-basis: 41.6667%;
    max-width: 41.6667%;
    padding: 0;
  }

  .col-xl-offset-10 {
    margin-left: 41.6667%;
  }

  .col-xl-11 {
    box-sizing: border-box;
    flex-basis: 45.8333%;
    max-width: 45.8333%;
    padding: 0;
  }

  .col-xl-offset-11 {
    margin-left: 45.8333%;
  }

  .col-xl-12 {
    box-sizing: border-box;
    flex-basis: 50%;
    max-width: 50%;
    padding: 0;
  }

  .col-xl-offset-12 {
    margin-left: 50%;
  }

  .col-xl-13 {
    box-sizing: border-box;
    flex-basis: 54.1667%;
    max-width: 54.1667%;
    padding: 0;
  }

  .col-xl-offset-13 {
    margin-left: 54.1667%;
  }

  .col-xl-14 {
    box-sizing: border-box;
    flex-basis: 58.3333%;
    max-width: 58.3333%;
    padding: 0;
  }

  .col-xl-offset-14 {
    margin-left: 58.3333%;
  }

  .col-xl-15 {
    box-sizing: border-box;
    flex-basis: 62.5%;
    max-width: 62.5%;
    padding: 0;
  }

  .col-xl-offset-15 {
    margin-left: 62.5%;
  }

  .col-xl-16 {
    box-sizing: border-box;
    flex-basis: 66.6667%;
    max-width: 66.6667%;
    padding: 0;
  }

  .col-xl-offset-16 {
    margin-left: 66.6667%;
  }

  .col-xl-17 {
    box-sizing: border-box;
    flex-basis: 70.8333%;
    max-width: 70.8333%;
    padding: 0;
  }

  .col-xl-offset-17 {
    margin-left: 70.8333%;
  }

  .col-xl-18 {
    box-sizing: border-box;
    flex-basis: 75%;
    max-width: 75%;
    padding: 0;
  }

  .col-xl-offset-18 {
    margin-left: 75%;
  }

  .col-xl-19 {
    box-sizing: border-box;
    flex-basis: 79.1667%;
    max-width: 79.1667%;
    padding: 0;
  }

  .col-xl-offset-19 {
    margin-left: 79.1667%;
  }

  .col-xl-20 {
    box-sizing: border-box;
    flex-basis: 83.3333%;
    max-width: 83.3333%;
    padding: 0;
  }

  .col-xl-offset-20 {
    margin-left: 83.3333%;
  }

  .col-xl-21 {
    box-sizing: border-box;
    flex-basis: 87.5%;
    max-width: 87.5%;
    padding: 0;
  }

  .col-xl-offset-21 {
    margin-left: 87.5%;
  }

  .col-xl-22 {
    box-sizing: border-box;
    flex-basis: 91.6667%;
    max-width: 91.6667%;
    padding: 0;
  }

  .col-xl-offset-22 {
    margin-left: 91.6667%;
  }

  .col-xl-23 {
    box-sizing: border-box;
    flex-basis: 95.8333%;
    max-width: 95.8333%;
    padding: 0;
  }

  .col-xl-offset-23 {
    margin-left: 95.8333%;
  }

  .col-xl-24 {
    box-sizing: border-box;
    flex-basis: 100%;
    max-width: 100%;
    padding: 0;
  }

  .col-xl-offset-24 {
    margin-left: 100%;
  }

  .row.start-xl {
    justify-content: flex-start;
  }

  .row.center-xl {
    justify-content: center;
  }

  .row.end-xl {
    justify-content: flex-end;
  }

  .row.top-xl {
    align-items: flex-start;
  }

  .row.middle-xl {
    align-items: center;
  }

  .row.bottom-xl {
    align-items: flex-end;
  }

  .row.around-xl {
    justify-content: space-around;
  }

  .row.between-xl {
    justify-content: space-between;
  }

  .first-xl {
    order: -1;
  }

  .last-xl {
    order: 1;
  }
}

.col-gutter-lr, .col-no-gutter {
  padding: 0;
}

.show {
  display: block !important;
}

.row.show {
  display: flex !important;
}

.hide {
  display: none !important;
}

.show-xs {
  display: block !important;
}

.row.show-xs {
  display: flex !important;
}

.hide-xs {
  display: none !important;
}

@media only screen and (max-width: 640px) {
  .show-xs-only {
    display: block !important;
  }

  .row.show-xs-only {
    display: flex !important;
  }

  .hide-xs-only {
    display: none !important;
  }
}

@media only screen and (min-width: 641px) {
  .show-sm {
    display: block !important;
  }

  .row.show-sm {
    display: flex !important;
  }

  .hide-sm {
    display: none !important;
  }
}

@media only screen and (min-width: 641px) and (max-width: 1023px) {
  .show-sm-only {
    display: block !important;
  }

  .row.show-sm-only {
    display: flex !important;
  }

  .hide-sm-only {
    display: none !important;
  }
}

@media only screen and (min-width: 1024px) {
  .show-md {
    display: block !important;
  }

  .row.show-md {
    display: flex !important;
  }

  .hide-md {
    display: none !important;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1439px) {
  .show-md-only {
    display: block !important;
  }

  .row.show-md-only {
    display: flex !important;
  }

  .hide-md-only {
    display: none !important;
  }
}

@media only screen and (min-width: 1440px) {
  .show-lg {
    display: block !important;
  }

  .row.show-lg {
    display: flex !important;
  }

  .hide-lg {
    display: none !important;
  }
}

@media only screen and (min-width: 1440px) and (max-width: 1790px) {
  .show-lg-only {
    display: block !important;
  }

  .row.show-lg-only {
    display: flex !important;
  }

  .hide-lg-only {
    display: none !important;
  }
}

@media only screen and (min-width: 1791px) {
  .show-xl {
    display: block !important;
  }

  .row.show-xl {
    display: flex !important;
  }

  .hide-xl {
    display: none !important;
  }
}

@keyframes plyr-progress {
  to {
    background-position: var(--plyr-progress-loading-size, 25px) 0;
  }
}

@keyframes plyr-popup {
  0% {
    opacity: .5;
    transform: translateY(10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes plyr-fade-in {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.plyr {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  direction: ltr;
  font-family: var(--plyr-font-family, inherit);
  font-variant-numeric: tabular-nums;
  font-weight: var(--plyr-font-weight-regular, 400);
  line-height: var(--plyr-line-height, 1.7);
  text-shadow: none;
  z-index: 0;
  flex-direction: column;
  align-items: center;
  min-width: 200px;
  max-width: 100%;
  transition: box-shadow .3s;
  display: flex;
  position: relative;
}

.plyr video, .plyr audio, .plyr iframe {
  width: 100%;
  height: 100%;
  display: block;
}

.plyr button {
  font: inherit;
  line-height: inherit;
  width: auto;
}

.plyr:focus {
  outline: 0;
}

.plyr--full-ui {
  box-sizing: border-box;
}

.plyr--full-ui *, .plyr--full-ui :after, .plyr--full-ui :before {
  box-sizing: inherit;
}

.plyr--full-ui a, .plyr--full-ui button, .plyr--full-ui input, .plyr--full-ui label {
  touch-action: manipulation;
}

.plyr__badge {
  background: var(--plyr-badge-background, #4a5464);
  border-radius: var(--plyr-badge-border-radius, 2px);
  color: var(--plyr-badge-text-color, #fff);
  font-size: var(--plyr-font-size-badge, 9px);
  padding: 3px 4px;
  line-height: 1;
}

.plyr--full-ui ::-webkit-media-text-track-container {
  display: none;
}

.plyr__captions {
  font-size: var(--plyr-font-size-small, 13px);
  padding: var(--plyr-control-spacing, 10px);
  text-align: center;
  width: 100%;
  transition: transform .4s ease-in-out;
  animation: .3s plyr-fade-in;
  display: none;
  position: absolute;
  bottom: 0;
  left: 0;
}

.plyr__captions span:empty {
  display: none;
}

@media (min-width: 480px) {
  .plyr__captions {
    font-size: var(--plyr-font-size-base, 15px);
    padding: calc(var(--plyr-control-spacing, 10px) * 2);
  }
}

@media (min-width: 768px) {
  .plyr__captions {
    font-size: var(--plyr-font-size-large, 18px);
  }
}

.plyr--captions-active .plyr__captions {
  display: block;
}

.plyr:not(.plyr--hide-controls) .plyr__controls:not(:empty) ~ .plyr__captions {
  transform: translateY(calc(var(--plyr-control-spacing, 10px) * -4));
}

.plyr__caption {
  background: var(--plyr-captions-background, #000c);
  -webkit-box-decoration-break: clone;
  box-decoration-break: clone;
  color: var(--plyr-captions-text-color, #fff);
  white-space: pre-wrap;
  border-radius: 2px;
  padding: .2em .5em;
  line-height: 185%;
}

.plyr__caption div {
  display: inline;
}

.plyr__control {
  border-radius: var(--plyr-control-radius, 4px);
  color: inherit;
  cursor: pointer;
  padding: calc(var(--plyr-control-spacing, 10px) * .7);
  background: none;
  border: 0;
  flex-shrink: 0;
  transition: all .3s;
  position: relative;
  overflow: visible;
}

.plyr__control svg {
  fill: currentColor;
  height: var(--plyr-control-icon-size, 18px);
  pointer-events: none;
  width: var(--plyr-control-icon-size, 18px);
  display: block;
}

.plyr__control:focus {
  outline: 0;
}

.plyr__control:focus-visible {
  outline: 2px dashed var(--plyr-focus-visible-color, var(--plyr-color-main, var(--plyr-color-main, #00b2ff)));
  outline-offset: 2px;
}

a.plyr__control {
  text-decoration: none;
}

a.plyr__control:after, a.plyr__control:before, .plyr__control:not(.plyr__control--pressed) .icon--pressed, .plyr__control.plyr__control--pressed .icon--not-pressed, .plyr__control:not(.plyr__control--pressed) .label--pressed, .plyr__control.plyr__control--pressed .label--not-pressed {
  display: none;
}

.plyr--full-ui ::-webkit-media-controls {
  display: none;
}

.plyr__controls {
  text-align: center;
  justify-content: flex-end;
  align-items: center;
  display: flex;
}

.plyr__controls .plyr__progress__container {
  flex: 1;
  min-width: 0;
}

.plyr__controls .plyr__controls__item {
  margin-left: calc(var(--plyr-control-spacing, 10px) / 4);
}

.plyr__controls .plyr__controls__item:first-child {
  margin-left: 0;
  margin-right: auto;
}

.plyr__controls .plyr__controls__item.plyr__progress__container {
  padding-left: calc(var(--plyr-control-spacing, 10px) / 4);
}

.plyr__controls .plyr__controls__item.plyr__time {
  padding: 0 calc(var(--plyr-control-spacing, 10px) / 2);
}

.plyr__controls .plyr__controls__item.plyr__progress__container:first-child, .plyr__controls .plyr__controls__item.plyr__time:first-child, .plyr__controls .plyr__controls__item.plyr__time + .plyr__time {
  padding-left: 0;
}

.plyr__controls:empty, .plyr [data-plyr="captions"], .plyr [data-plyr="pip"], .plyr [data-plyr="airplay"], .plyr [data-plyr="fullscreen"] {
  display: none;
}

.plyr--captions-enabled [data-plyr="captions"], .plyr--pip-supported [data-plyr="pip"], .plyr--airplay-supported [data-plyr="airplay"], .plyr--fullscreen-enabled [data-plyr="fullscreen"] {
  display: inline-block;
}

.plyr__menu {
  display: flex;
  position: relative;
}

.plyr__menu .plyr__control svg {
  transition: transform .3s;
}

.plyr__menu .plyr__control[aria-expanded="true"] svg {
  transform: rotate(90deg);
}

.plyr__menu .plyr__control[aria-expanded="true"] .plyr__tooltip {
  display: none;
}

.plyr__menu__container {
  background: var(--plyr-menu-background, #ffffffe6);
  border-radius: var(--plyr-menu-radius, 8px);
  box-shadow: var(--plyr-menu-shadow, 0 1px 2px #00000026);
  color: var(--plyr-menu-color, #4a5464);
  font-size: var(--plyr-font-size-base, 15px);
  text-align: left;
  white-space: nowrap;
  z-index: 3;
  margin-bottom: 10px;
  animation: .2s plyr-popup;
  position: absolute;
  bottom: 100%;
  right: -3px;
}

.plyr__menu__container > div {
  transition: height .35s cubic-bezier(.4, 0, .2, 1), width .35s cubic-bezier(.4, 0, .2, 1);
  overflow: hidden;
}

.plyr__menu__container:after {
  border: var(--plyr-menu-arrow-size, 4px) solid transparent;
  border-top-color: var(--plyr-menu-background, #ffffffe6);
  content: "";
  right: calc(((var(--plyr-control-icon-size, 18px) / 2)  + calc(var(--plyr-control-spacing, 10px) * .7))  - (var(--plyr-menu-arrow-size, 4px) / 2));
  width: 0;
  height: 0;
  position: absolute;
  top: 100%;
}

.plyr__menu__container [role="menu"] {
  padding: calc(var(--plyr-control-spacing, 10px) * .7);
}

.plyr__menu__container [role="menuitem"], .plyr__menu__container [role="menuitemradio"] {
  margin-top: 2px;
}

.plyr__menu__container [role="menuitem"]:first-child, .plyr__menu__container [role="menuitemradio"]:first-child {
  margin-top: 0;
}

.plyr__menu__container .plyr__control {
  color: var(--plyr-menu-color, #4a5464);
  font-size: var(--plyr-font-size-menu, var(--plyr-font-size-small, 13px));
  padding: calc(calc(var(--plyr-control-spacing, 10px) * .7) / 1.5) calc(calc(var(--plyr-control-spacing, 10px) * .7) * 1.5);
  -webkit-user-select: none;
  user-select: none;
  align-items: center;
  width: 100%;
  display: flex;
}

.plyr__menu__container .plyr__control > span {
  align-items: inherit;
  width: 100%;
  display: flex;
}

.plyr__menu__container .plyr__control:after {
  border: var(--plyr-menu-item-arrow-size, 4px) solid transparent;
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.plyr__menu__container .plyr__control--forward {
  padding-right: calc(calc(var(--plyr-control-spacing, 10px) * .7) * 4);
}

.plyr__menu__container .plyr__control--forward:after {
  border-left-color: var(--plyr-menu-arrow-color, #728197);
  right: calc((calc(var(--plyr-control-spacing, 10px) * .7) * 1.5)  - var(--plyr-menu-item-arrow-size, 4px));
}

.plyr__menu__container .plyr__control--forward:focus-visible:after, .plyr__menu__container .plyr__control--forward:hover:after {
  border-left-color: currentColor;
}

.plyr__menu__container .plyr__control--back {
  font-weight: var(--plyr-font-weight-regular, 400);
  margin: calc(var(--plyr-control-spacing, 10px) * .7);
  margin-bottom: calc(calc(var(--plyr-control-spacing, 10px) * .7) / 2);
  padding-left: calc(calc(var(--plyr-control-spacing, 10px) * .7) * 4);
  width: calc(100% - (calc(var(--plyr-control-spacing, 10px) * .7) * 2));
  position: relative;
}

.plyr__menu__container .plyr__control--back:after {
  border-right-color: var(--plyr-menu-arrow-color, #728197);
  left: calc((calc(var(--plyr-control-spacing, 10px) * .7) * 1.5)  - var(--plyr-menu-item-arrow-size, 4px));
}

.plyr__menu__container .plyr__control--back:before {
  background: var(--plyr-menu-back-border-color, #dcdfe5);
  box-shadow: 0 1px 0 var(--plyr-menu-back-border-shadow-color, #fff);
  content: "";
  margin-top: calc(calc(var(--plyr-control-spacing, 10px) * .7) / 2);
  height: 1px;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  overflow: hidden;
}

.plyr__menu__container .plyr__control--back:focus-visible:after, .plyr__menu__container .plyr__control--back:hover:after {
  border-right-color: currentColor;
}

.plyr__menu__container .plyr__control[role="menuitemradio"] {
  padding-left: calc(var(--plyr-control-spacing, 10px) * .7);
}

.plyr__menu__container .plyr__control[role="menuitemradio"]:before, .plyr__menu__container .plyr__control[role="menuitemradio"]:after {
  border-radius: 100%;
}

.plyr__menu__container .plyr__control[role="menuitemradio"]:before {
  content: "";
  margin-right: var(--plyr-control-spacing, 10px);
  background: #0000001a;
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  transition: all .3s;
  display: block;
}

.plyr__menu__container .plyr__control[role="menuitemradio"]:after {
  opacity: 0;
  background: #fff;
  border: 0;
  width: 6px;
  height: 6px;
  transition: transform .3s, opacity .3s;
  top: 50%;
  left: 12px;
  transform: translateY(-50%)scale(0);
}

.plyr__menu__container .plyr__control[role="menuitemradio"][aria-checked="true"]:before {
  background: var(--plyr-control-toggle-checked-background, var(--plyr-color-main, var(--plyr-color-main, #00b2ff)));
}

.plyr__menu__container .plyr__control[role="menuitemradio"][aria-checked="true"]:after {
  opacity: 1;
  transform: translateY(-50%)scale(1);
}

.plyr__menu__container .plyr__control[role="menuitemradio"]:focus-visible:before, .plyr__menu__container .plyr__control[role="menuitemradio"]:hover:before {
  background: #23282f1a;
}

.plyr__menu__container .plyr__menu__value {
  margin-left: auto;
  margin-right: calc((calc(var(--plyr-control-spacing, 10px) * .7)  - 2px) * -1);
  padding-left: calc(calc(var(--plyr-control-spacing, 10px) * .7) * 3.5);
  pointer-events: none;
  align-items: center;
  display: flex;
  overflow: hidden;
}

.plyr--full-ui input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  border-radius: calc(var(--plyr-range-thumb-height, 13px) * 2);
  color: var(--plyr-range-fill-background, var(--plyr-color-main, var(--plyr-color-main, #00b2ff)));
  height: calc((var(--plyr-range-thumb-active-shadow-width, 3px) * 2)  + var(--plyr-range-thumb-height, 13px));
  background: none;
  border: 0;
  width: 100%;
  min-width: 0;
  margin: 0;
  padding: 0;
  transition: box-shadow .3s;
  display: block;
}

.plyr--full-ui input[type="range"]::-webkit-slider-runnable-track {
  border-radius: calc(var(--plyr-range-track-height, 5px) / 2);
  height: var(--plyr-range-track-height, 5px);
  -webkit-user-select: none;
  user-select: none;
  background: none;
  background-image: linear-gradient(to right, currentColor var(--value, 0%), transparent var(--value, 0%));
  border: 0;
  transition: box-shadow .3s;
}

.plyr--full-ui input[type="range"]::-webkit-slider-thumb {
  background: var(--plyr-range-thumb-background, #fff);
  box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px #23282f26, 0 0 0 1px #23282f33);
  height: var(--plyr-range-thumb-height, 13px);
  width: var(--plyr-range-thumb-height, 13px);
  -webkit-appearance: none;
  appearance: none;
  margin-top: calc((var(--plyr-range-thumb-height, 13px)  - var(--plyr-range-track-height, 5px)) / 2 * -1);
  border: 0;
  border-radius: 100%;
  transition: all .2s;
  position: relative;
}

.plyr--full-ui input[type="range"]::-moz-range-track {
  border-radius: calc(var(--plyr-range-track-height, 5px) / 2);
  height: var(--plyr-range-track-height, 5px);
  -webkit-user-select: none;
  user-select: none;
  background: none;
  border: 0;
  transition: box-shadow .3s;
}

.plyr--full-ui input[type="range"]::-moz-range-thumb {
  background: var(--plyr-range-thumb-background, #fff);
  box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px #23282f26, 0 0 0 1px #23282f33);
  height: var(--plyr-range-thumb-height, 13px);
  width: var(--plyr-range-thumb-height, 13px);
  border: 0;
  border-radius: 100%;
  transition: all .2s;
  position: relative;
}

.plyr--full-ui input[type="range"]::-moz-range-progress {
  border-radius: calc(var(--plyr-range-track-height, 5px) / 2);
  height: var(--plyr-range-track-height, 5px);
  background: currentColor;
}

.plyr--full-ui input[type="range"]::-ms-track {
  border-radius: calc(var(--plyr-range-track-height, 5px) / 2);
  height: var(--plyr-range-track-height, 5px);
  -webkit-user-select: none;
  user-select: none;
  color: #0000;
  background: none;
  border: 0;
  transition: box-shadow .3s;
}

.plyr--full-ui input[type="range"]::-ms-fill-upper {
  border-radius: calc(var(--plyr-range-track-height, 5px) / 2);
  height: var(--plyr-range-track-height, 5px);
  -webkit-user-select: none;
  user-select: none;
  background: none;
  border: 0;
  transition: box-shadow .3s;
}

.plyr--full-ui input[type="range"]::-ms-fill-lower {
  border-radius: calc(var(--plyr-range-track-height, 5px) / 2);
  height: var(--plyr-range-track-height, 5px);
  -webkit-user-select: none;
  user-select: none;
  background: currentColor;
  border: 0;
  transition: box-shadow .3s;
}

.plyr--full-ui input[type="range"]::-ms-thumb {
  background: var(--plyr-range-thumb-background, #fff);
  box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px #23282f26, 0 0 0 1px #23282f33);
  height: var(--plyr-range-thumb-height, 13px);
  width: var(--plyr-range-thumb-height, 13px);
  border: 0;
  border-radius: 100%;
  margin-top: 0;
  transition: all .2s;
  position: relative;
}

.plyr--full-ui input[type="range"]::-ms-tooltip {
  display: none;
}

.plyr--full-ui input[type="range"]::-moz-focus-outer {
  border: 0;
}

.plyr--full-ui input[type="range"]:focus {
  outline: 0;
}

.plyr--full-ui input[type="range"]:focus-visible::-webkit-slider-runnable-track {
  outline: 2px dashed var(--plyr-focus-visible-color, var(--plyr-color-main, var(--plyr-color-main, #00b2ff)));
  outline-offset: 2px;
}

.plyr--full-ui input[type="range"]:focus-visible::-moz-range-track {
  outline: 2px dashed var(--plyr-focus-visible-color, var(--plyr-color-main, var(--plyr-color-main, #00b2ff)));
  outline-offset: 2px;
}

.plyr--full-ui input[type="range"]:focus-visible::-ms-track {
  outline: 2px dashed var(--plyr-focus-visible-color, var(--plyr-color-main, var(--plyr-color-main, #00b2ff)));
  outline-offset: 2px;
}

.plyr__poster {
  background-color: var(--plyr-video-background, var(--plyr-video-background, #000));
  opacity: 0;
  z-index: 1;
  background-position: 50%;
  background-repeat: no-repeat;
  background-size: contain;
  width: 100%;
  height: 100%;
  transition: opacity .2s;
  position: absolute;
  top: 0;
  left: 0;
}

.plyr--stopped.plyr__poster-enabled .plyr__poster {
  opacity: 1;
}

.plyr--youtube.plyr--paused.plyr__poster-enabled:not(.plyr--stopped) .plyr__poster {
  display: none;
}

.plyr__time {
  font-size: var(--plyr-font-size-time, var(--plyr-font-size-small, 13px));
}

.plyr__time + .plyr__time:before {
  content: "⁄";
  margin-right: var(--plyr-control-spacing, 10px);
}

@media (max-width: 767px) {
  .plyr__time + .plyr__time {
    display: none;
  }
}

.plyr__tooltip {
  background: var(--plyr-tooltip-background, #fff);
  border-radius: var(--plyr-tooltip-radius, 5px);
  box-shadow: var(--plyr-tooltip-shadow, 0 1px 2px #00000026);
  color: var(--plyr-tooltip-color, #4a5464);
  font-size: var(--plyr-font-size-small, 13px);
  font-weight: var(--plyr-font-weight-regular, 400);
  margin-bottom: calc(calc(var(--plyr-control-spacing, 10px) / 2) * 2);
  opacity: 0;
  padding: calc(var(--plyr-control-spacing, 10px) / 2) calc(calc(var(--plyr-control-spacing, 10px) / 2) * 1.5);
  pointer-events: none;
  transform-origin: 50% 100%;
  white-space: nowrap;
  z-index: 2;
  line-height: 1.3;
  transition: transform .2s .1s, opacity .2s .1s;
  position: absolute;
  bottom: 100%;
  left: 50%;
  transform: translate(-50%, 10px)scale(.8);
}

.plyr__tooltip:before {
  border-left: var(--plyr-tooltip-arrow-size, 4px) solid transparent;
  border-right: var(--plyr-tooltip-arrow-size, 4px) solid transparent;
  border-top: var(--plyr-tooltip-arrow-size, 4px) solid var(--plyr-tooltip-background, #fff);
  bottom: calc(var(--plyr-tooltip-arrow-size, 4px) * -1);
  content: "";
  z-index: 2;
  width: 0;
  height: 0;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.plyr .plyr__control:hover .plyr__tooltip, .plyr .plyr__control:focus-visible .plyr__tooltip, .plyr__tooltip--visible {
  opacity: 1;
  transform: translate(-50%)scale(1);
}

.plyr .plyr__control:hover .plyr__tooltip {
  z-index: 3;
}

.plyr__controls > .plyr__control:first-child .plyr__tooltip, .plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip {
  transform-origin: 0 100%;
  left: 0;
  transform: translate(0, 10px)scale(.8);
}

.plyr__controls > .plyr__control:first-child .plyr__tooltip:before, .plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip:before {
  left: calc((var(--plyr-control-icon-size, 18px) / 2)  + calc(var(--plyr-control-spacing, 10px) * .7));
}

.plyr__controls > .plyr__control:last-child .plyr__tooltip {
  transform-origin: 100% 100%;
  left: auto;
  right: 0;
  transform: translate(0, 10px)scale(.8);
}

.plyr__controls > .plyr__control:last-child .plyr__tooltip:before {
  left: auto;
  right: calc((var(--plyr-control-icon-size, 18px) / 2)  + calc(var(--plyr-control-spacing, 10px) * .7));
  transform: translateX(50%);
}

.plyr__controls > .plyr__control:first-child:hover .plyr__tooltip, .plyr__controls > .plyr__control:first-child:focus-visible .plyr__tooltip, .plyr__controls > .plyr__control:first-child .plyr__tooltip--visible, .plyr__controls > .plyr__control:first-child + .plyr__control:hover .plyr__tooltip, .plyr__controls > .plyr__control:first-child + .plyr__control:focus-visible .plyr__tooltip, .plyr__controls > .plyr__control:first-child + .plyr__control .plyr__tooltip--visible, .plyr__controls > .plyr__control:last-child:hover .plyr__tooltip, .plyr__controls > .plyr__control:last-child:focus-visible .plyr__tooltip, .plyr__controls > .plyr__control:last-child .plyr__tooltip--visible {
  transform: translate(0)scale(1);
}

.plyr__progress {
  left: calc(var(--plyr-range-thumb-height, 13px) * .5);
  margin-right: var(--plyr-range-thumb-height, 13px);
  position: relative;
}

.plyr__progress input[type="range"], .plyr__progress__buffer {
  margin-left: calc(var(--plyr-range-thumb-height, 13px) * -.5);
  margin-right: calc(var(--plyr-range-thumb-height, 13px) * -.5);
  width: calc(100% + var(--plyr-range-thumb-height, 13px));
}

.plyr__progress input[type="range"] {
  z-index: 2;
  position: relative;
}

.plyr__progress .plyr__tooltip {
  overflow-wrap: break-word;
  max-width: 120px;
  left: 0;
}

.plyr__progress__buffer {
  -webkit-appearance: none;
  height: var(--plyr-range-track-height, 5px);
  margin-top: calc((var(--plyr-range-track-height, 5px) / 2) * -1);
  background: none;
  border: 0;
  border-radius: 100px;
  padding: 0;
  position: absolute;
  top: 50%;
  left: 0;
}

.plyr__progress__buffer::-webkit-progress-bar {
  background: none;
}

.plyr__progress__buffer::-webkit-progress-value {
  min-width: var(--plyr-range-track-height, 5px);
  background: currentColor;
  border-radius: 100px;
  transition: width .2s;
}

.plyr__progress__buffer::-moz-progress-bar {
  min-width: var(--plyr-range-track-height, 5px);
  background: currentColor;
  border-radius: 100px;
  transition: width .2s;
}

.plyr__progress__buffer::-ms-fill {
  border-radius: 100px;
  transition: width .2s;
}

.plyr--loading .plyr__progress__buffer {
  background-image: linear-gradient(-45deg, var(--plyr-progress-loading-background, #23282f99) 25%, transparent 25%, transparent 50%, var(--plyr-progress-loading-background, #23282f99) 50%, var(--plyr-progress-loading-background, #23282f99) 75%, transparent 75%, transparent);
  background-repeat: repeat-x;
  background-size: var(--plyr-progress-loading-size, 25px) var(--plyr-progress-loading-size, 25px);
  color: #0000;
  animation: 1s linear infinite plyr-progress;
}

.plyr--video.plyr--loading .plyr__progress__buffer {
  background-color: var(--plyr-video-progress-buffered-background, #ffffff40);
}

.plyr--audio.plyr--loading .plyr__progress__buffer {
  background-color: var(--plyr-audio-progress-buffered-background, #c1c8d199);
}

.plyr__progress__marker {
  background-color: var(--plyr-progress-marker-background, #fff);
  height: var(--plyr-range-track-height, 5px);
  width: var(--plyr-progress-marker-width, 3px);
  z-index: 3;
  border-radius: 1px;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.plyr__volume {
  align-items: center;
  display: flex;
  position: relative;
}

.plyr__volume input[type="range"] {
  margin-left: calc(var(--plyr-control-spacing, 10px) / 2);
  margin-right: calc(var(--plyr-control-spacing, 10px) / 2);
  z-index: 2;
  min-width: 60px;
  max-width: 90px;
  position: relative;
}

.plyr--audio {
  display: block;
}

.plyr--audio .plyr__controls {
  background: var(--plyr-audio-controls-background, #fff);
  border-radius: inherit;
  color: var(--plyr-audio-control-color, #4a5464);
  padding: var(--plyr-control-spacing, 10px);
}

.plyr--audio .plyr__control:focus-visible, .plyr--audio .plyr__control:hover, .plyr--audio .plyr__control[aria-expanded="true"] {
  background: var(--plyr-audio-control-background-hover, var(--plyr-color-main, var(--plyr-color-main, #00b2ff)));
  color: var(--plyr-audio-control-color-hover, #fff);
}

.plyr--full-ui.plyr--audio input[type="range"]::-webkit-slider-runnable-track {
  background-color: var(--plyr-audio-range-track-background, var(--plyr-audio-progress-buffered-background, #c1c8d199));
}

.plyr--full-ui.plyr--audio input[type="range"]::-moz-range-track {
  background-color: var(--plyr-audio-range-track-background, var(--plyr-audio-progress-buffered-background, #c1c8d199));
}

.plyr--full-ui.plyr--audio input[type="range"]::-ms-track {
  background-color: var(--plyr-audio-range-track-background, var(--plyr-audio-progress-buffered-background, #c1c8d199));
}

.plyr--full-ui.plyr--audio input[type="range"]:active::-webkit-slider-thumb {
  box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px #23282f26, 0 0 0 1px #23282f33), 0 0 0 var(--plyr-range-thumb-active-shadow-width, 3px) var(--plyr-audio-range-thumb-active-shadow-color, #23282f1a);
}

.plyr--full-ui.plyr--audio input[type="range"]:active::-moz-range-thumb {
  box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px #23282f26, 0 0 0 1px #23282f33), 0 0 0 var(--plyr-range-thumb-active-shadow-width, 3px) var(--plyr-audio-range-thumb-active-shadow-color, #23282f1a);
}

.plyr--full-ui.plyr--audio input[type="range"]:active::-ms-thumb {
  box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px #23282f26, 0 0 0 1px #23282f33), 0 0 0 var(--plyr-range-thumb-active-shadow-width, 3px) var(--plyr-audio-range-thumb-active-shadow-color, #23282f1a);
}

.plyr--audio .plyr__progress__buffer {
  color: var(--plyr-audio-progress-buffered-background, #c1c8d199);
}

.plyr--video {
  overflow: hidden;
}

.plyr--video.plyr--menu-open {
  overflow: visible;
}

.plyr__video-wrapper {
  background: var(--plyr-video-background, var(--plyr-video-background, #000));
  border-radius: inherit;
  width: 100%;
  height: 100%;
  margin: auto;
  position: relative;
  overflow: hidden;
}

.plyr__video-embed, .plyr__video-wrapper--fixed-ratio {
  aspect-ratio: 16 / 9;
}

@supports not (aspect-ratio: 16/9) {
  .plyr__video-embed, .plyr__video-wrapper--fixed-ratio {
    height: 0;
    padding-bottom: 56.25%;
    position: relative;
  }
}

.plyr__video-embed iframe, .plyr__video-wrapper--fixed-ratio video {
  border: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.plyr--full-ui .plyr__video-embed > .plyr__video-embed__container {
  padding-bottom: 240%;
  position: relative;
  transform: translateY(-38.2813%);
}

.plyr--video .plyr__controls {
  background: var(--plyr-video-controls-background, linear-gradient(#0000, #000000bf));
  border-bottom-left-radius: inherit;
  border-bottom-right-radius: inherit;
  color: var(--plyr-video-control-color, #fff);
  padding: calc(var(--plyr-control-spacing, 10px) / 2);
  padding-top: calc(var(--plyr-control-spacing, 10px) * 2);
  z-index: 3;
  transition: opacity .4s ease-in-out, transform .4s ease-in-out;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

@media (min-width: 480px) {
  .plyr--video .plyr__controls {
    padding: var(--plyr-control-spacing, 10px);
    padding-top: calc(var(--plyr-control-spacing, 10px) * 3.5);
  }
}

.plyr--video.plyr--hide-controls .plyr__controls {
  opacity: 0;
  pointer-events: none;
  transform: translateY(100%);
}

.plyr--video .plyr__control:focus-visible, .plyr--video .plyr__control:hover, .plyr--video .plyr__control[aria-expanded="true"] {
  background: var(--plyr-video-control-background-hover, var(--plyr-color-main, var(--plyr-color-main, #00b2ff)));
  color: var(--plyr-video-control-color-hover, #fff);
}

.plyr__control--overlaid {
  background: var(--plyr-video-control-background-hover, var(--plyr-color-main, var(--plyr-color-main, #00b2ff)));
  color: var(--plyr-video-control-color, #fff);
  opacity: .9;
  padding: calc(var(--plyr-control-spacing, 10px) * 1.5);
  z-index: 2;
  border: 0;
  border-radius: 100%;
  transition: all .3s;
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.plyr__control--overlaid svg {
  position: relative;
  left: 2px;
}

.plyr__control--overlaid:hover, .plyr__control--overlaid:focus {
  opacity: 1;
}

.plyr--playing .plyr__control--overlaid {
  opacity: 0;
  visibility: hidden;
}

.plyr--full-ui.plyr--video .plyr__control--overlaid {
  display: block;
}

.plyr--full-ui.plyr--video input[type="range"]::-webkit-slider-runnable-track {
  background-color: var(--plyr-video-range-track-background, var(--plyr-video-progress-buffered-background, #ffffff40));
}

.plyr--full-ui.plyr--video input[type="range"]::-moz-range-track {
  background-color: var(--plyr-video-range-track-background, var(--plyr-video-progress-buffered-background, #ffffff40));
}

.plyr--full-ui.plyr--video input[type="range"]::-ms-track {
  background-color: var(--plyr-video-range-track-background, var(--plyr-video-progress-buffered-background, #ffffff40));
}

.plyr--full-ui.plyr--video input[type="range"]:active::-webkit-slider-thumb {
  box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px #23282f26, 0 0 0 1px #23282f33), 0 0 0 var(--plyr-range-thumb-active-shadow-width, 3px) var(--plyr-audio-range-thumb-active-shadow-color, #ffffff80);
}

.plyr--full-ui.plyr--video input[type="range"]:active::-moz-range-thumb {
  box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px #23282f26, 0 0 0 1px #23282f33), 0 0 0 var(--plyr-range-thumb-active-shadow-width, 3px) var(--plyr-audio-range-thumb-active-shadow-color, #ffffff80);
}

.plyr--full-ui.plyr--video input[type="range"]:active::-ms-thumb {
  box-shadow: var(--plyr-range-thumb-shadow, 0 1px 1px #23282f26, 0 0 0 1px #23282f33), 0 0 0 var(--plyr-range-thumb-active-shadow-width, 3px) var(--plyr-audio-range-thumb-active-shadow-color, #ffffff80);
}

.plyr--video .plyr__progress__buffer {
  color: var(--plyr-video-progress-buffered-background, #ffffff40);
}

.plyr:-webkit-full-screen {
  background: #000;
  width: 100%;
  height: 100%;
  margin: 0;
  border-radius: 0 !important;
}

.plyr:fullscreen {
  background: #000;
  width: 100%;
  height: 100%;
  margin: 0;
  border-radius: 0 !important;
}

.plyr:-webkit-full-screen video {
  height: 100%;
}

.plyr:fullscreen video {
  height: 100%;
}

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen {
  display: block;
}

.plyr:fullscreen .plyr__control .icon--exit-fullscreen {
  display: block;
}

.plyr:-webkit-full-screen .plyr__control .icon--exit-fullscreen + svg {
  display: none;
}

.plyr:fullscreen .plyr__control .icon--exit-fullscreen + svg {
  display: none;
}

.plyr:-webkit-full-screen.plyr--hide-controls {
  cursor: none;
}

.plyr:fullscreen.plyr--hide-controls {
  cursor: none;
}

@media (min-width: 1024px) {
  .plyr:-webkit-full-screen .plyr__captions {
    font-size: var(--plyr-font-size-xlarge, 21px);
  }

  .plyr:fullscreen .plyr__captions {
    font-size: var(--plyr-font-size-xlarge, 21px);
  }
}

.plyr--fullscreen-fallback {
  z-index: 10000000;
  background: #000;
  width: 100%;
  height: 100%;
  margin: 0;
  position: fixed;
  inset: 0;
  border-radius: 0 !important;
}

.plyr--fullscreen-fallback video {
  height: 100%;
}

.plyr--fullscreen-fallback .plyr__control .icon--exit-fullscreen {
  display: block;
}

.plyr--fullscreen-fallback .plyr__control .icon--exit-fullscreen + svg {
  display: none;
}

.plyr--fullscreen-fallback.plyr--hide-controls {
  cursor: none;
}

@media (min-width: 1024px) {
  .plyr--fullscreen-fallback .plyr__captions {
    font-size: var(--plyr-font-size-xlarge, 21px);
  }
}

.plyr__ads {
  border-radius: inherit;
  cursor: pointer;
  z-index: -1;
  position: absolute;
  inset: 0;
  overflow: hidden;
}

.plyr__ads > div, .plyr__ads > div iframe {
  width: 100%;
  height: 100%;
  position: absolute;
}

.plyr__ads:after {
  bottom: var(--plyr-control-spacing, 10px);
  color: #fff;
  content: attr(data-badge-text);
  pointer-events: none;
  right: var(--plyr-control-spacing, 10px);
  z-index: 3;
  background: #23282f;
  border-radius: 2px;
  padding: 2px 6px;
  font-size: 11px;
  position: absolute;
}

.plyr__ads:empty:after {
  display: none;
}

.plyr__cues {
  height: var(--plyr-range-track-height, 5px);
  opacity: .8;
  z-index: 3;
  background: currentColor;
  width: 3px;
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.plyr__preview-thumb {
  background-color: var(--plyr-tooltip-background, #fff);
  border-radius: var(--plyr-menu-radius, 8px);
  box-shadow: var(--plyr-tooltip-shadow, 0 1px 2px #00000026);
  margin-bottom: calc(calc(var(--plyr-control-spacing, 10px) / 2) * 2);
  opacity: 0;
  pointer-events: none;
  transform-origin: 50% 100%;
  z-index: 2;
  padding: 3px;
  transition: transform .2s .1s, opacity .2s .1s;
  position: absolute;
  bottom: 100%;
  transform: translate(0, 10px)scale(.8);
}

.plyr__preview-thumb--is-shown {
  opacity: 1;
  transform: translate(0)scale(1);
}

.plyr__preview-thumb:before {
  border-left: var(--plyr-tooltip-arrow-size, 4px) solid transparent;
  border-right: var(--plyr-tooltip-arrow-size, 4px) solid transparent;
  border-top: var(--plyr-tooltip-arrow-size, 4px) solid var(--plyr-tooltip-background, #fff);
  bottom: calc(var(--plyr-tooltip-arrow-size, 4px) * -1);
  content: "";
  left: calc(50% + var(--preview-arrow-offset));
  z-index: 2;
  width: 0;
  height: 0;
  position: absolute;
  transform: translateX(-50%);
}

.plyr__preview-thumb__image-container {
  border-radius: calc(var(--plyr-menu-radius, 8px)  - 1px);
  z-index: 0;
  background: #c1c8d1;
  position: relative;
  overflow: hidden;
}

.plyr__preview-thumb__image-container img, .plyr__preview-thumb__image-container:after {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.plyr__preview-thumb__image-container:after {
  border-radius: inherit;
  content: "";
  pointer-events: none;
  box-shadow: inset 0 0 0 1px #00000026;
}

.plyr__preview-thumb__image-container img {
  max-width: none;
  max-height: none;
}

.plyr__preview-thumb__time-container {
  background: var(--plyr-video-controls-background, linear-gradient(#0000, #000000bf));
  border-bottom-left-radius: calc(var(--plyr-menu-radius, 8px)  - 1px);
  border-bottom-right-radius: calc(var(--plyr-menu-radius, 8px)  - 1px);
  z-index: 3;
  padding: 20px 6px 6px;
  line-height: 1.1;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.plyr__preview-thumb__time-container span {
  color: #fff;
  font-size: var(--plyr-font-size-time, var(--plyr-font-size-small, 13px));
}

.plyr__preview-scrubbing {
  filter: blur(1px);
  opacity: 0;
  pointer-events: none;
  z-index: 1;
  width: 100%;
  height: 100%;
  margin: auto;
  transition: opacity .3s;
  position: absolute;
  inset: 0;
  overflow: hidden;
}

.plyr__preview-scrubbing--is-shown {
  opacity: 1;
}

.plyr__preview-scrubbing img {
  object-fit: contain;
  width: 100%;
  max-width: none;
  height: 100%;
  max-height: none;
  position: absolute;
  top: 0;
  left: 0;
}

.plyr--no-transition {
  transition: none !important;
}

.plyr__sr-only {
  clip: rect(1px, 1px, 1px, 1px);
  overflow: hidden;
  border: 0 !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  position: absolute !important;
}

.plyr [hidden] {
  display: none !important;
}

.col-xs {
  padding: 0;
}

@media only screen and (min-width: 641px) {
  .col-xs {
    padding: 0;
  }
}

.col-xs-1 {
  padding: 0;
}

@media only screen and (min-width: 641px) {
  .col-xs-1 {
    padding: 0;
  }
}

.col-xs-2 {
  padding: 0;
}

@media only screen and (min-width: 641px) {
  .col-xs-2 {
    padding: 0;
  }
}

.col-xs-3 {
  padding: 0;
}

@media only screen and (min-width: 641px) {
  .col-xs-3 {
    padding: 0;
  }
}

.col-xs-4 {
  padding: 0;
}

@media only screen and (min-width: 641px) {
  .col-xs-4 {
    padding: 0;
  }
}

.col-xs-5 {
  padding: 0;
}

@media only screen and (min-width: 641px) {
  .col-xs-5 {
    padding: 0;
  }
}

.col-xs-6 {
  padding: 0;
}

@media only screen and (min-width: 641px) {
  .col-xs-6 {
    padding: 0;
  }
}

.col-xs-7 {
  padding: 0;
}

@media only screen and (min-width: 641px) {
  .col-xs-7 {
    padding: 0;
  }
}

.col-xs-8 {
  padding: 0;
}

@media only screen and (min-width: 641px) {
  .col-xs-8 {
    padding: 0;
  }
}

.col-xs-9 {
  padding: 0;
}

@media only screen and (min-width: 641px) {
  .col-xs-9 {
    padding: 0;
  }
}

.col-xs-10 {
  padding: 0;
}

@media only screen and (min-width: 641px) {
  .col-xs-10 {
    padding: 0;
  }
}

.col-xs-11 {
  padding: 0;
}

@media only screen and (min-width: 641px) {
  .col-xs-11 {
    padding: 0;
  }
}

.col-xs-12 {
  padding: 0;
}

@media only screen and (min-width: 641px) {
  .col-xs-12 {
    padding: 0;
  }
}

.col-xs-13 {
  padding: 0;
}

@media only screen and (min-width: 641px) {
  .col-xs-13 {
    padding: 0;
  }
}

.col-xs-14 {
  padding: 0;
}

@media only screen and (min-width: 641px) {
  .col-xs-14 {
    padding: 0;
  }
}

.col-xs-15 {
  padding: 0;
}

@media only screen and (min-width: 641px) {
  .col-xs-15 {
    padding: 0;
  }
}

.col-xs-16 {
  padding: 0;
}

@media only screen and (min-width: 641px) {
  .col-xs-16 {
    padding: 0;
  }
}

.col-xs-17 {
  padding: 0;
}

@media only screen and (min-width: 641px) {
  .col-xs-17 {
    padding: 0;
  }
}

.col-xs-18 {
  padding: 0;
}

@media only screen and (min-width: 641px) {
  .col-xs-18 {
    padding: 0;
  }
}

.col-xs-19 {
  padding: 0;
}

@media only screen and (min-width: 641px) {
  .col-xs-19 {
    padding: 0;
  }
}

.col-xs-20 {
  padding: 0;
}

@media only screen and (min-width: 641px) {
  .col-xs-20 {
    padding: 0;
  }
}

.col-xs-21 {
  padding: 0;
}

@media only screen and (min-width: 641px) {
  .col-xs-21 {
    padding: 0;
  }
}

.col-xs-22 {
  padding: 0;
}

@media only screen and (min-width: 641px) {
  .col-xs-22 {
    padding: 0;
  }
}

.col-xs-23 {
  padding: 0;
}

@media only screen and (min-width: 641px) {
  .col-xs-23 {
    padding: 0;
  }
}

.col-xs-24 {
  padding: 0;
}

@media only screen and (min-width: 641px) {
  .col-xs-24, .col-sm {
    padding: 0;
  }
}

@media only screen and (min-width: 641px) and (min-width: 641px) {
  .col-sm {
    padding: 0;
  }
}

@media only screen and (min-width: 641px) {
  .col-sm-1 {
    padding: 0;
  }
}

@media only screen and (min-width: 641px) and (min-width: 641px) {
  .col-sm-1 {
    padding: 0;
  }
}

@media only screen and (min-width: 641px) {
  .col-sm-2 {
    padding: 0;
  }
}

@media only screen and (min-width: 641px) and (min-width: 641px) {
  .col-sm-2 {
    padding: 0;
  }
}

@media only screen and (min-width: 641px) {
  .col-sm-3 {
    padding: 0;
  }
}

@media only screen and (min-width: 641px) and (min-width: 641px) {
  .col-sm-3 {
    padding: 0;
  }
}

@media only screen and (min-width: 641px) {
  .col-sm-4 {
    padding: 0;
  }
}

@media only screen and (min-width: 641px) and (min-width: 641px) {
  .col-sm-4 {
    padding: 0;
  }
}

@media only screen and (min-width: 641px) {
  .col-sm-5 {
    padding: 0;
  }
}

@media only screen and (min-width: 641px) and (min-width: 641px) {
  .col-sm-5 {
    padding: 0;
  }
}

@media only screen and (min-width: 641px) {
  .col-sm-6 {
    padding: 0;
  }
}

@media only screen and (min-width: 641px) and (min-width: 641px) {
  .col-sm-6 {
    padding: 0;
  }
}

@media only screen and (min-width: 641px) {
  .col-sm-7 {
    padding: 0;
  }
}

@media only screen and (min-width: 641px) and (min-width: 641px) {
  .col-sm-7 {
    padding: 0;
  }
}

@media only screen and (min-width: 641px) {
  .col-sm-8 {
    padding: 0;
  }
}

@media only screen and (min-width: 641px) and (min-width: 641px) {
  .col-sm-8 {
    padding: 0;
  }
}

@media only screen and (min-width: 641px) {
  .col-sm-9 {
    padding: 0;
  }
}

@media only screen and (min-width: 641px) and (min-width: 641px) {
  .col-sm-9 {
    padding: 0;
  }
}

@media only screen and (min-width: 641px) {
  .col-sm-10 {
    padding: 0;
  }
}

@media only screen and (min-width: 641px) and (min-width: 641px) {
  .col-sm-10 {
    padding: 0;
  }
}

@media only screen and (min-width: 641px) {
  .col-sm-11 {
    padding: 0;
  }
}

@media only screen and (min-width: 641px) and (min-width: 641px) {
  .col-sm-11 {
    padding: 0;
  }
}

@media only screen and (min-width: 641px) {
  .col-sm-12 {
    padding: 0;
  }
}

@media only screen and (min-width: 641px) and (min-width: 641px) {
  .col-sm-12 {
    padding: 0;
  }
}

@media only screen and (min-width: 641px) {
  .col-sm-13 {
    padding: 0;
  }
}

@media only screen and (min-width: 641px) and (min-width: 641px) {
  .col-sm-13 {
    padding: 0;
  }
}

@media only screen and (min-width: 641px) {
  .col-sm-14 {
    padding: 0;
  }
}

@media only screen and (min-width: 641px) and (min-width: 641px) {
  .col-sm-14 {
    padding: 0;
  }
}

@media only screen and (min-width: 641px) {
  .col-sm-15 {
    padding: 0;
  }
}

@media only screen and (min-width: 641px) and (min-width: 641px) {
  .col-sm-15 {
    padding: 0;
  }
}

@media only screen and (min-width: 641px) {
  .col-sm-16 {
    padding: 0;
  }
}

@media only screen and (min-width: 641px) and (min-width: 641px) {
  .col-sm-16 {
    padding: 0;
  }
}

@media only screen and (min-width: 641px) {
  .col-sm-17 {
    padding: 0;
  }
}

@media only screen and (min-width: 641px) and (min-width: 641px) {
  .col-sm-17 {
    padding: 0;
  }
}

@media only screen and (min-width: 641px) {
  .col-sm-18 {
    padding: 0;
  }
}

@media only screen and (min-width: 641px) and (min-width: 641px) {
  .col-sm-18 {
    padding: 0;
  }
}

@media only screen and (min-width: 641px) {
  .col-sm-19 {
    padding: 0;
  }
}

@media only screen and (min-width: 641px) and (min-width: 641px) {
  .col-sm-19 {
    padding: 0;
  }
}

@media only screen and (min-width: 641px) {
  .col-sm-20 {
    padding: 0;
  }
}

@media only screen and (min-width: 641px) and (min-width: 641px) {
  .col-sm-20 {
    padding: 0;
  }
}

@media only screen and (min-width: 641px) {
  .col-sm-21 {
    padding: 0;
  }
}

@media only screen and (min-width: 641px) and (min-width: 641px) {
  .col-sm-21 {
    padding: 0;
  }
}

@media only screen and (min-width: 641px) {
  .col-sm-22 {
    padding: 0;
  }
}

@media only screen and (min-width: 641px) and (min-width: 641px) {
  .col-sm-22 {
    padding: 0;
  }
}

@media only screen and (min-width: 641px) {
  .col-sm-23 {
    padding: 0;
  }
}

@media only screen and (min-width: 641px) and (min-width: 641px) {
  .col-sm-23 {
    padding: 0;
  }
}

@media only screen and (min-width: 641px) {
  .col-sm-24 {
    padding: 0;
  }
}

@media only screen and (min-width: 641px) and (min-width: 641px) {
  .col-sm-24 {
    padding: 0;
  }
}

@media only screen and (min-width: 1024px) {
  .col-md {
    padding: 0;
  }
}

@media only screen and (min-width: 1024px) and (min-width: 641px) {
  .col-md {
    padding: 0;
  }
}

@media only screen and (min-width: 1024px) {
  .col-md-1 {
    padding: 0;
  }
}

@media only screen and (min-width: 1024px) and (min-width: 641px) {
  .col-md-1 {
    padding: 0;
  }
}

@media only screen and (min-width: 1024px) {
  .col-md-2 {
    padding: 0;
  }
}

@media only screen and (min-width: 1024px) and (min-width: 641px) {
  .col-md-2 {
    padding: 0;
  }
}

@media only screen and (min-width: 1024px) {
  .col-md-3 {
    padding: 0;
  }
}

@media only screen and (min-width: 1024px) and (min-width: 641px) {
  .col-md-3 {
    padding: 0;
  }
}

@media only screen and (min-width: 1024px) {
  .col-md-4 {
    padding: 0;
  }
}

@media only screen and (min-width: 1024px) and (min-width: 641px) {
  .col-md-4 {
    padding: 0;
  }
}

@media only screen and (min-width: 1024px) {
  .col-md-5 {
    padding: 0;
  }
}

@media only screen and (min-width: 1024px) and (min-width: 641px) {
  .col-md-5 {
    padding: 0;
  }
}

@media only screen and (min-width: 1024px) {
  .col-md-6 {
    padding: 0;
  }
}

@media only screen and (min-width: 1024px) and (min-width: 641px) {
  .col-md-6 {
    padding: 0;
  }
}

@media only screen and (min-width: 1024px) {
  .col-md-7 {
    padding: 0;
  }
}

@media only screen and (min-width: 1024px) and (min-width: 641px) {
  .col-md-7 {
    padding: 0;
  }
}

@media only screen and (min-width: 1024px) {
  .col-md-8 {
    padding: 0;
  }
}

@media only screen and (min-width: 1024px) and (min-width: 641px) {
  .col-md-8 {
    padding: 0;
  }
}

@media only screen and (min-width: 1024px) {
  .col-md-9 {
    padding: 0;
  }
}

@media only screen and (min-width: 1024px) and (min-width: 641px) {
  .col-md-9 {
    padding: 0;
  }
}

@media only screen and (min-width: 1024px) {
  .col-md-10 {
    padding: 0;
  }
}

@media only screen and (min-width: 1024px) and (min-width: 641px) {
  .col-md-10 {
    padding: 0;
  }
}

@media only screen and (min-width: 1024px) {
  .col-md-11 {
    padding: 0;
  }
}

@media only screen and (min-width: 1024px) and (min-width: 641px) {
  .col-md-11 {
    padding: 0;
  }
}

@media only screen and (min-width: 1024px) {
  .col-md-12 {
    padding: 0;
  }
}

@media only screen and (min-width: 1024px) and (min-width: 641px) {
  .col-md-12 {
    padding: 0;
  }
}

@media only screen and (min-width: 1024px) {
  .col-md-13 {
    padding: 0;
  }
}

@media only screen and (min-width: 1024px) and (min-width: 641px) {
  .col-md-13 {
    padding: 0;
  }
}

@media only screen and (min-width: 1024px) {
  .col-md-14 {
    padding: 0;
  }
}

@media only screen and (min-width: 1024px) and (min-width: 641px) {
  .col-md-14 {
    padding: 0;
  }
}

@media only screen and (min-width: 1024px) {
  .col-md-15 {
    padding: 0;
  }
}

@media only screen and (min-width: 1024px) and (min-width: 641px) {
  .col-md-15 {
    padding: 0;
  }
}

@media only screen and (min-width: 1024px) {
  .col-md-16 {
    padding: 0;
  }
}

@media only screen and (min-width: 1024px) and (min-width: 641px) {
  .col-md-16 {
    padding: 0;
  }
}

@media only screen and (min-width: 1024px) {
  .col-md-17 {
    padding: 0;
  }
}

@media only screen and (min-width: 1024px) and (min-width: 641px) {
  .col-md-17 {
    padding: 0;
  }
}

@media only screen and (min-width: 1024px) {
  .col-md-18 {
    padding: 0;
  }
}

@media only screen and (min-width: 1024px) and (min-width: 641px) {
  .col-md-18 {
    padding: 0;
  }
}

@media only screen and (min-width: 1024px) {
  .col-md-19 {
    padding: 0;
  }
}

@media only screen and (min-width: 1024px) and (min-width: 641px) {
  .col-md-19 {
    padding: 0;
  }
}

@media only screen and (min-width: 1024px) {
  .col-md-20 {
    padding: 0;
  }
}

@media only screen and (min-width: 1024px) and (min-width: 641px) {
  .col-md-20 {
    padding: 0;
  }
}

@media only screen and (min-width: 1024px) {
  .col-md-21 {
    padding: 0;
  }
}

@media only screen and (min-width: 1024px) and (min-width: 641px) {
  .col-md-21 {
    padding: 0;
  }
}

@media only screen and (min-width: 1024px) {
  .col-md-22 {
    padding: 0;
  }
}

@media only screen and (min-width: 1024px) and (min-width: 641px) {
  .col-md-22 {
    padding: 0;
  }
}

@media only screen and (min-width: 1024px) {
  .col-md-23 {
    padding: 0;
  }
}

@media only screen and (min-width: 1024px) and (min-width: 641px) {
  .col-md-23 {
    padding: 0;
  }
}

@media only screen and (min-width: 1024px) {
  .col-md-24 {
    padding: 0;
  }
}

@media only screen and (min-width: 1024px) and (min-width: 641px) {
  .col-md-24 {
    padding: 0;
  }
}

@media only screen and (min-width: 1440px) {
  .col-lg {
    padding: 0;
  }
}

@media only screen and (min-width: 1440px) and (min-width: 641px) {
  .col-lg {
    padding: 0;
  }
}

@media only screen and (min-width: 1440px) {
  .col-lg-1 {
    padding: 0;
  }
}

@media only screen and (min-width: 1440px) and (min-width: 641px) {
  .col-lg-1 {
    padding: 0;
  }
}

@media only screen and (min-width: 1440px) {
  .col-lg-2 {
    padding: 0;
  }
}

@media only screen and (min-width: 1440px) and (min-width: 641px) {
  .col-lg-2 {
    padding: 0;
  }
}

@media only screen and (min-width: 1440px) {
  .col-lg-3 {
    padding: 0;
  }
}

@media only screen and (min-width: 1440px) and (min-width: 641px) {
  .col-lg-3 {
    padding: 0;
  }
}

@media only screen and (min-width: 1440px) {
  .col-lg-4 {
    padding: 0;
  }
}

@media only screen and (min-width: 1440px) and (min-width: 641px) {
  .col-lg-4 {
    padding: 0;
  }
}

@media only screen and (min-width: 1440px) {
  .col-lg-5 {
    padding: 0;
  }
}

@media only screen and (min-width: 1440px) and (min-width: 641px) {
  .col-lg-5 {
    padding: 0;
  }
}

@media only screen and (min-width: 1440px) {
  .col-lg-6 {
    padding: 0;
  }
}

@media only screen and (min-width: 1440px) and (min-width: 641px) {
  .col-lg-6 {
    padding: 0;
  }
}

@media only screen and (min-width: 1440px) {
  .col-lg-7 {
    padding: 0;
  }
}

@media only screen and (min-width: 1440px) and (min-width: 641px) {
  .col-lg-7 {
    padding: 0;
  }
}

@media only screen and (min-width: 1440px) {
  .col-lg-8 {
    padding: 0;
  }
}

@media only screen and (min-width: 1440px) and (min-width: 641px) {
  .col-lg-8 {
    padding: 0;
  }
}

@media only screen and (min-width: 1440px) {
  .col-lg-9 {
    padding: 0;
  }
}

@media only screen and (min-width: 1440px) and (min-width: 641px) {
  .col-lg-9 {
    padding: 0;
  }
}

@media only screen and (min-width: 1440px) {
  .col-lg-10 {
    padding: 0;
  }
}

@media only screen and (min-width: 1440px) and (min-width: 641px) {
  .col-lg-10 {
    padding: 0;
  }
}

@media only screen and (min-width: 1440px) {
  .col-lg-11 {
    padding: 0;
  }
}

@media only screen and (min-width: 1440px) and (min-width: 641px) {
  .col-lg-11 {
    padding: 0;
  }
}

@media only screen and (min-width: 1440px) {
  .col-lg-12 {
    padding: 0;
  }
}

@media only screen and (min-width: 1440px) and (min-width: 641px) {
  .col-lg-12 {
    padding: 0;
  }
}

@media only screen and (min-width: 1440px) {
  .col-lg-13 {
    padding: 0;
  }
}

@media only screen and (min-width: 1440px) and (min-width: 641px) {
  .col-lg-13 {
    padding: 0;
  }
}

@media only screen and (min-width: 1440px) {
  .col-lg-14 {
    padding: 0;
  }
}

@media only screen and (min-width: 1440px) and (min-width: 641px) {
  .col-lg-14 {
    padding: 0;
  }
}

@media only screen and (min-width: 1440px) {
  .col-lg-15 {
    padding: 0;
  }
}

@media only screen and (min-width: 1440px) and (min-width: 641px) {
  .col-lg-15 {
    padding: 0;
  }
}

@media only screen and (min-width: 1440px) {
  .col-lg-16 {
    padding: 0;
  }
}

@media only screen and (min-width: 1440px) and (min-width: 641px) {
  .col-lg-16 {
    padding: 0;
  }
}

@media only screen and (min-width: 1440px) {
  .col-lg-17 {
    padding: 0;
  }
}

@media only screen and (min-width: 1440px) and (min-width: 641px) {
  .col-lg-17 {
    padding: 0;
  }
}

@media only screen and (min-width: 1440px) {
  .col-lg-18 {
    padding: 0;
  }
}

@media only screen and (min-width: 1440px) and (min-width: 641px) {
  .col-lg-18 {
    padding: 0;
  }
}

@media only screen and (min-width: 1440px) {
  .col-lg-19 {
    padding: 0;
  }
}

@media only screen and (min-width: 1440px) and (min-width: 641px) {
  .col-lg-19 {
    padding: 0;
  }
}

@media only screen and (min-width: 1440px) {
  .col-lg-20 {
    padding: 0;
  }
}

@media only screen and (min-width: 1440px) and (min-width: 641px) {
  .col-lg-20 {
    padding: 0;
  }
}

@media only screen and (min-width: 1440px) {
  .col-lg-21 {
    padding: 0;
  }
}

@media only screen and (min-width: 1440px) and (min-width: 641px) {
  .col-lg-21 {
    padding: 0;
  }
}

@media only screen and (min-width: 1440px) {
  .col-lg-22 {
    padding: 0;
  }
}

@media only screen and (min-width: 1440px) and (min-width: 641px) {
  .col-lg-22 {
    padding: 0;
  }
}

@media only screen and (min-width: 1440px) {
  .col-lg-23 {
    padding: 0;
  }
}

@media only screen and (min-width: 1440px) and (min-width: 641px) {
  .col-lg-23 {
    padding: 0;
  }
}

@media only screen and (min-width: 1440px) {
  .col-lg-24 {
    padding: 0;
  }
}

@media only screen and (min-width: 1440px) and (min-width: 641px) {
  .col-lg-24 {
    padding: 0;
  }
}

@media only screen and (min-width: 1791px) {
  .col-xl {
    padding: 0;
  }
}

@media only screen and (min-width: 1791px) and (min-width: 641px) {
  .col-xl {
    padding: 0;
  }
}

@media only screen and (min-width: 1791px) {
  .col-xl-1 {
    padding: 0;
  }
}

@media only screen and (min-width: 1791px) and (min-width: 641px) {
  .col-xl-1 {
    padding: 0;
  }
}

@media only screen and (min-width: 1791px) {
  .col-xl-2 {
    padding: 0;
  }
}

@media only screen and (min-width: 1791px) and (min-width: 641px) {
  .col-xl-2 {
    padding: 0;
  }
}

@media only screen and (min-width: 1791px) {
  .col-xl-3 {
    padding: 0;
  }
}

@media only screen and (min-width: 1791px) and (min-width: 641px) {
  .col-xl-3 {
    padding: 0;
  }
}

@media only screen and (min-width: 1791px) {
  .col-xl-4 {
    padding: 0;
  }
}

@media only screen and (min-width: 1791px) and (min-width: 641px) {
  .col-xl-4 {
    padding: 0;
  }
}

@media only screen and (min-width: 1791px) {
  .col-xl-5 {
    padding: 0;
  }
}

@media only screen and (min-width: 1791px) and (min-width: 641px) {
  .col-xl-5 {
    padding: 0;
  }
}

@media only screen and (min-width: 1791px) {
  .col-xl-6 {
    padding: 0;
  }
}

@media only screen and (min-width: 1791px) and (min-width: 641px) {
  .col-xl-6 {
    padding: 0;
  }
}

@media only screen and (min-width: 1791px) {
  .col-xl-7 {
    padding: 0;
  }
}

@media only screen and (min-width: 1791px) and (min-width: 641px) {
  .col-xl-7 {
    padding: 0;
  }
}

@media only screen and (min-width: 1791px) {
  .col-xl-8 {
    padding: 0;
  }
}

@media only screen and (min-width: 1791px) and (min-width: 641px) {
  .col-xl-8 {
    padding: 0;
  }
}

@media only screen and (min-width: 1791px) {
  .col-xl-9 {
    padding: 0;
  }
}

@media only screen and (min-width: 1791px) and (min-width: 641px) {
  .col-xl-9 {
    padding: 0;
  }
}

@media only screen and (min-width: 1791px) {
  .col-xl-10 {
    padding: 0;
  }
}

@media only screen and (min-width: 1791px) and (min-width: 641px) {
  .col-xl-10 {
    padding: 0;
  }
}

@media only screen and (min-width: 1791px) {
  .col-xl-11 {
    padding: 0;
  }
}

@media only screen and (min-width: 1791px) and (min-width: 641px) {
  .col-xl-11 {
    padding: 0;
  }
}

@media only screen and (min-width: 1791px) {
  .col-xl-12 {
    padding: 0;
  }
}

@media only screen and (min-width: 1791px) and (min-width: 641px) {
  .col-xl-12 {
    padding: 0;
  }
}

@media only screen and (min-width: 1791px) {
  .col-xl-13 {
    padding: 0;
  }
}

@media only screen and (min-width: 1791px) and (min-width: 641px) {
  .col-xl-13 {
    padding: 0;
  }
}

@media only screen and (min-width: 1791px) {
  .col-xl-14 {
    padding: 0;
  }
}

@media only screen and (min-width: 1791px) and (min-width: 641px) {
  .col-xl-14 {
    padding: 0;
  }
}

@media only screen and (min-width: 1791px) {
  .col-xl-15 {
    padding: 0;
  }
}

@media only screen and (min-width: 1791px) and (min-width: 641px) {
  .col-xl-15 {
    padding: 0;
  }
}

@media only screen and (min-width: 1791px) {
  .col-xl-16 {
    padding: 0;
  }
}

@media only screen and (min-width: 1791px) and (min-width: 641px) {
  .col-xl-16 {
    padding: 0;
  }
}

@media only screen and (min-width: 1791px) {
  .col-xl-17 {
    padding: 0;
  }
}

@media only screen and (min-width: 1791px) and (min-width: 641px) {
  .col-xl-17 {
    padding: 0;
  }
}

@media only screen and (min-width: 1791px) {
  .col-xl-18 {
    padding: 0;
  }
}

@media only screen and (min-width: 1791px) and (min-width: 641px) {
  .col-xl-18 {
    padding: 0;
  }
}

@media only screen and (min-width: 1791px) {
  .col-xl-19 {
    padding: 0;
  }
}

@media only screen and (min-width: 1791px) and (min-width: 641px) {
  .col-xl-19 {
    padding: 0;
  }
}

@media only screen and (min-width: 1791px) {
  .col-xl-20 {
    padding: 0;
  }
}

@media only screen and (min-width: 1791px) and (min-width: 641px) {
  .col-xl-20 {
    padding: 0;
  }
}

@media only screen and (min-width: 1791px) {
  .col-xl-21 {
    padding: 0;
  }
}

@media only screen and (min-width: 1791px) and (min-width: 641px) {
  .col-xl-21 {
    padding: 0;
  }
}

@media only screen and (min-width: 1791px) {
  .col-xl-22 {
    padding: 0;
  }
}

@media only screen and (min-width: 1791px) and (min-width: 641px) {
  .col-xl-22 {
    padding: 0;
  }
}

@media only screen and (min-width: 1791px) {
  .col-xl-23 {
    padding: 0;
  }
}

@media only screen and (min-width: 1791px) and (min-width: 641px) {
  .col-xl-23 {
    padding: 0;
  }
}

@media only screen and (min-width: 1791px) {
  .col-xl-24 {
    padding: 0;
  }
}

@media only screen and (min-width: 1791px) and (min-width: 641px) {
  .col-xl-24 {
    padding: 0;
  }
}

html {
  --plyr-range-track-height: .5rem;
  --plyr-color-main: black;
  --plyr-progress-loading-background: $ color-green-dark;
  --plyr-video-progress-buffered-background: transparent;
  --plyr-audio-progress-buffered-background: transparent;
  --progress-video: 0;
}

@media only screen and (min-width: 1024px) {
  html {
    --plyr-range-track-height: 1rem;
  }
}

.plyr {
  flex-direction: column;
  display: flex;
}

.plyr--video .plyr__controls {
  background: none;
  order: 2;
  justify-content: space-between;
  width: 100%;
  margin-top: -1px;
  padding: 0;
  position: relative;
  opacity: 1 !important;
  transform: none !important;
}

.plyr--video .plyr__controls > * {
  height: 2rem;
  margin: 0;
  padding: .2rem 0;
}

@media only screen and (min-width: 1024px) {
  .plyr--video .plyr__controls > * {
    height: 2.2rem;
    padding: 0;
  }
}

.plyr--video .plyr__controls .plyr__control {
  background: #005b53;
  overflow: hidden;
}

.plyr--video .plyr__controls .plyr__control svg {
  object-fit: contain;
  object-position: center;
  width: auto;
  height: 100%;
}

.plyr--video .plyr__controls .plyr__control svg * {
  fill: #0000;
}

@media only screen and (min-width: 1024px) {
  .plyr--video .plyr__controls .plyr__control:hover svg .fill-hover {
    fill: #d9ff56 !important;
  }

  .plyr--video .plyr__controls .plyr__control:hover svg .stroke-hover {
    stroke: #d9ff56 !important;
  }
}

.plyr--video .plyr__controls [data-plyr="play"] {
  border: 1px solid #d9ff56;
  border-radius: .4rem;
  min-width: 2rem;
  margin-right: -1px;
}

@media only screen and (min-width: 1024px) {
  .plyr--video .plyr__controls [data-plyr="play"] {
    border-radius: .7rem;
    min-width: 3.5rem;
  }
}

.plyr--video .plyr__controls [data-plyr="play"] svg {
  margin-left: auto;
  margin-right: auto;
}

.plyr--video .plyr__controls .plyr__time {
  color: #20c569;
  background: #005b53;
  border-top: 1px solid #d9ff56;
  border-bottom: 1px solid #d9ff56;
  align-items: center;
  height: 2rem;
  padding-top: .2rem;
  font-size: .6rem;
  font-weight: bold;
  display: flex;
  overflow: hidden;
}

@media only screen and (min-width: 1024px) {
  .plyr--video .plyr__controls .plyr__time {
    height: 2.2rem;
    font-size: .666667rem;
  }
}

.plyr--video .plyr__controls .plyr__time--current {
  border-left: 1px solid #d9ff56;
  border-right: 0;
  border-radius: .4rem 0 0 .4rem;
  padding-left: .5rem;
}

@media only screen and (min-width: 1024px) {
  .plyr--video .plyr__controls .plyr__time--current {
    border-radius: .7rem 0 0 .7rem;
    padding-left: .8rem;
  }
}

.plyr--video .plyr__controls .plyr__progress__container {
  background: #005b53;
  border-top: 1px solid #d9ff56;
  border-bottom: 1px solid #d9ff56;
  border-right: 0;
  align-items: center;
  padding-left: .2rem;
  padding-right: .2rem;
  display: flex;
}

@media only screen and (min-width: 1024px) {
  .plyr--video .plyr__controls .plyr__progress__container {
    padding-left: .6rem;
    padding-right: .6rem;
  }
}

.plyr--video .plyr__controls .plyr__progress__container .plyr__progress {
  flex-grow: 1;
}

.plyr--video .plyr__controls .plyr__progress__container .plyr__progress, .plyr--video .plyr__controls .plyr__progress__container .plyr__progress * {
  cursor: pointer;
}

.plyr--video .plyr__controls .plyr__volume {
  align-items: initial;
  border-top: 1px solid #d9ff56;
  border-bottom: 1px solid #d9ff56;
  padding: 0;
}

.plyr--video .plyr__controls .plyr__volume button {
  width: 100%;
  padding: .2rem 0;
}

@media only screen and (min-width: 1024px) {
  .plyr--video .plyr__controls .plyr__volume button {
    padding: 0;
  }
}

.plyr--video .plyr__controls [data-plyr="fullscreen"] {
  border: 1px solid #d9ff56;
  border-left: 0;
  border-radius: 0 .4rem .4rem 0;
  padding-right: .3rem;
}

@media only screen and (min-width: 1024px) {
  .plyr--video .plyr__controls [data-plyr="fullscreen"] {
    border-radius: 0 .7rem .7rem 0;
  }
}

.plyr--full-ui.plyr--audio input[type="range"], .plyr--full-ui.plyr--video input[type="range"] {
  color: #0000;
  opacity: .3;
  background-image: linear-gradient(90deg, #6aa0ff 0%, #20c569 80%);
  background-repeat: no-repeat;
  background-size: 100% 100%;
  height: .7rem;
}

@media only screen and (min-width: 1024px) {
  .plyr--full-ui.plyr--audio input[type="range"], .plyr--full-ui.plyr--video input[type="range"] {
    height: 1rem;
  }
}

.plyr__poster {
  border: 1px solid;
  border-radius: .4rem;
  overflow: hidden;
}

@media only screen and (min-width: 1024px) {
  .plyr__poster {
    border-radius: .7rem;
  }
}

.plyr__video-wrapper {
  border-radius: .4rem;
  overflow: hidden;
}

@media only screen and (min-width: 1024px) {
  .plyr__video-wrapper {
    border-radius: .7rem;
  }
}

.video-progress-pointer {
  top: -.8rem;
  left: var(--progress-video);
  z-index: 2;
  pointer-events: none;
  width: 2.4rem;
  height: auto;
  margin-left: -1.2rem;
  position: absolute;
}

@media only screen and (min-width: 1024px) {
  .video-progress-pointer {
    width: 3rem;
    margin-left: -1.5rem;
    top: -.95rem;
  }

  .plyr__progress__container:hover .video-progress-pointer svg .fill-hover {
    fill: #20c569 !important;
  }

  .plyr__progress__container:hover .video-progress-pointer svg .stroke-hover {
    stroke: #20c569 !important;
  }
}

p, h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

ul, ol, li {
  margin: 0;
  padding: 0;
}

* {
  color: #d9ff56;
  font-family: ITC Serif Gothic Std;
  font-size: max(1.55945vw + 11.5439px, 16px);
  line-height: 1.3;
}

ul, ul *, ol, ol *, li, li *, p, p *, .body-text, .body-text * {
  letter-spacing: -.01em;
  font-family: ITC Serif Gothic Std;
  font-size: 1rem;
  line-height: 1.2;
}

@media only screen and (min-width: 1024px) {
  ul, ul *, ol, ol *, li, li *, p, p *, .body-text, .body-text * {
    letter-spacing: -.025em;
    line-height: 1.33;
  }
}

.body-text--variant, .case-study-filters, .case-study-filters *, .credits-line, .case-study-card__cat, .body-text--variant *, .credits-line *, .case-study-card__cat * {
  letter-spacing: -.01em;
  font-family: ITC Serif Gothic Std;
  font-size: .81rem;
  line-height: 1.2;
}

@media only screen and (min-width: 1024px) {
  .body-text--variant, .case-study-filters, .case-study-filters *, .credits-line, .case-study-card__cat, .body-text--variant *, .credits-line *, .case-study-card__cat * {
    letter-spacing: -.025em;
    font-size: 1rem;
    line-height: 1.33;
  }
}

.h1-text-style, .h1-text-style *, h1, h1 * {
  color: #06cad5;
  font-family: ABC Camera Plain;
  font-size: 2.66rem;
  line-height: 1;
}

@media only screen and (min-width: 1024px) {
  .h1-text-style, .h1-text-style *, h1, h1 * {
    font-size: 4.5rem;
    line-height: .88;
  }
}

.h2-text-style, .h2-text-style *, h2, h2 * {
  color: #005b53;
  font-family: ABC Camera;
  font-size: 2.66rem;
  line-height: 1;
}

@media only screen and (min-width: 1024px) {
  .h2-text-style, .h2-text-style *, h2, h2 * {
    font-size: 4.5rem;
    line-height: .88;
  }
}

.h3-text-style, .h3-text-style *, h3, h3 * {
  color: #06cad5;
  font-family: ABC Camera Plain;
  font-size: 1.66rem;
  line-height: 1.15;
}

@media only screen and (min-width: 1024px) {
  .h3-text-style, .h3-text-style *, h3, h3 * {
    font-size: 1.83333rem;
  }
}

.h4-text-style, .h4-text-style *, h4, h4 * {
  color: #20c569;
  letter-spacing: -.007em;
  font-size: 1.36rem;
  line-height: 1.1;
}

@media only screen and (min-width: 1024px) {
  .h4-text-style, .h4-text-style *, h4, h4 * {
    font-size: 1.83333rem;
  }
}

.h5-text-style, .h5-text-style *, h5, h5 * {
  font-size: 1.66667rem;
  line-height: 1.1;
}

.h6-text-style, .h6-text-style *, h6, h6 * {
  color: #20c569;
  font-size: 1rem;
  line-height: 1.15;
}

@media only screen and (min-width: 1024px) {
  .h6-text-style, .h6-text-style *, h6, h6 * {
    font-size: 1.16667rem;
  }
}

.figcaption, figcaption {
  color: #005b53;
  font-size: .666667rem;
}

.copyright-text, .copyright-text * {
  text-transform: uppercase;
  color: #005b53;
  font-size: .61rem;
  font-weight: bold;
  line-height: 1.2;
}

@media only screen and (min-width: 1024px) {
  .copyright-text, .copyright-text * {
    font-size: 1rem;
    line-height: 1.1;
  }
}

.follow-path-text, .follow-path-text * {
  text-transform: uppercase;
  color: #06cad5;
  font-size: 2.3rem;
}

@media only screen and (min-width: 1024px) {
  .follow-path-text, .follow-path-text * {
    font-size: 1.2rem;
  }
}

.heading-balloon, .heading-balloon * {
  color: #005b53;
  font-family: ABC Camera;
  font-size: 1.94rem;
}

@media only screen and (min-width: 1024px) {
  .heading-balloon, .heading-balloon * {
    font-size: 2.7rem;
  }
}

.ticker-text, .ticker-text * {
  text-transform: uppercase;
  letter-spacing: .05rem;
  text-shadow: 0 0 .4rem #d9ff56;
  font-size: 5.22rem;
  line-height: 1;
}

@media only screen and (min-width: 1024px) {
  .ticker-text, .ticker-text * {
    letter-spacing: .15rem;
    font-size: 8.53333rem;
  }
}

.ticker-text.text--green, .ticker-text .text--green {
  text-shadow: 0 0 .4rem #20c569;
}

.ticker-text.text--green-dark, .ticker-text .text--green-dark {
  text-shadow: 0 0 .4rem #005b53;
}

.ticker-text.text--blue, .ticker-text .text--blue {
  text-shadow: 0 0 .4rem #6aa0ff;
}

.ticker-text.text--cyan, .ticker-text .text--cyan {
  text-shadow: 0 0 .4rem #06cad5;
}

.ticker-text--variant, .ticker-text--variant * {
  letter-spacing: -.25rem;
  font-family: Relaate;
  line-height: .88;
}

@media only screen and (min-width: 1024px) {
  .ticker-text--variant, .ticker-text--variant * {
    letter-spacing: -.5rem;
  }
}

.body-text--floating-filters, .floating-filters__filter, .body-text--floating-filters *, .floating-filters__filter * {
  font-size: .7rem;
}

.text--green, .text--green * {
  color: #20c569;
}

.text--green-dark, .text--green-dark * {
  color: #005b53;
}

.text--cyan, .text--cyan * {
  color: #06cad5;
}

.text--yellow, .text--yellow * {
  color: #d9ff56;
}

.text--blue, .text--blue * {
  color: #6aa0ff;
}

.text--uppercase, .text--uppercase * {
  text-transform: uppercase;
}

ul:not(.list--no-style) {
  list-style-type: none;
}

ul:not(.list--no-style) li {
  padding-bottom: .3em;
  padding-left: 1.2em;
  position: relative;
}

ul:not(.list--no-style) li + li {
  border-top: 1px solid;
  padding-top: .5em;
}

ul:not(.list--no-style) li + li:before {
  top: .3em;
}

ul:not(.list--no-style) li:before {
  content: "";
  background: url("high-dive-star.94590c7f.svg") 0 0 no-repeat;
  width: 1.6em;
  height: 1.6em;
  position: absolute;
  top: -.2em;
  left: -.4em;
}

h1 + h2, h1 + h3, h1 + h4, h1 + h5, h1 + h6 {
  margin-top: .6rem;
}

h1 + p, h2 + p, h3 + p, h4 + p, h5 + p, h6 + p {
  margin-top: 1rem;
}

html, body {
  min-height: 100%;
}

body {
  margin: 0;
  padding: 0;
}

button {
  background: none;
  border: 0;
  outline: 0;
  text-decoration: none;
}

a:not(.link--no-style) {
  color: inherit;
  text-decoration: none;
}

a:not(.link--no-style):hover {
  text-decoration: none;
}

figure {
  margin: 0;
}

.column-test {
  background-color: #ff00001a;
  outline: 1px solid red;
  min-height: calc(100vh - 20px);
}

@media only screen and (min-width: 1024px) {
  .column-test {
    min-height: calc(100vh - 60px);
  }
}

body {
  background: linear-gradient(#6aa0ff 0%, #005b53 25vh, #000 45%) no-repeat;
}

@media only screen and (min-width: 1024px) {
  body {
    background: linear-gradient(#6aa0ff 0%, #005b53 65vh, #000 60%);
  }
}

.page-content {
  padding: 0 10px;
  overflow-x: hidden;
}

@media only screen and (min-width: 1024px) {
  .page-content {
    padding: 0 30px;
  }
}

figure {
  text-align: center;
}

figure video, figure img {
  border: 1px solid;
  border-radius: .4rem;
  width: 100%;
  height: auto;
  overflow: hidden;
}

@media only screen and (min-width: 1024px) {
  figure video, figure img {
    border-radius: .7rem;
  }
}

figure figcaption {
  margin-top: .5em;
}

.center-text {
  text-align: center;
}

@media only screen and (min-width: 1024px) {
  .center-text--desktop {
    text-align: center;
  }
}

.center-text--mobile {
  text-align: center;
}

@media only screen and (min-width: 1024px) {
  .center-text--mobile {
    text-align: left;
  }
}

.row + .row {
  margin-top: 1.5rem;
}

@media only screen and (min-width: 1024px) {
  .row + .row {
    margin-top: 2rem;
  }
}

[class^="col-xs-"] + [class^="col-xs-"] {
  margin-top: 1.5rem;
}

@media only screen and (min-width: 1024px) {
  [class^="col-xs-"] + [class^="col-xs-"] {
    margin-top: 0;
  }
}

.section--first {
  margin-top: 2em;
}

@media only screen and (min-width: 1024px) {
  .section--first {
    margin-top: 3.3em;
  }
}

.section + .section {
  margin-top: 2rem;
}

@media only screen and (min-width: 1024px) {
  .section + .section {
    margin-top: 3rem;
  }
}

.section--bg-wavy {
  background-image: url("wavy-bg.c6b32689.svg");
  background-position: center;
  background-size: cover;
  border-top: 1px solid #20c569;
  border-bottom: 1px solid #20c569;
  width: calc(100% + 20px);
  margin-left: -10px;
  margin-right: -10px;
  padding: 3.5rem 10px;
}

@media only screen and (min-width: 1024px) {
  .section--bg-wavy {
    width: calc(100% + 60px);
    margin-left: -30px;
    margin-right: -30px;
    padding-left: 30px;
    padding-right: 30px;
  }
}

.glow-block {
  background-color: #000;
  border: 1px solid #20c569;
  border-radius: .4rem;
  padding: 2rem 10px;
  box-shadow: 0 0 .6rem .03rem #20c569;
}

@media only screen and (min-width: 1024px) {
  .glow-block {
    border-radius: .7rem;
    padding: 1.5rem 3.5rem 4.5rem;
    box-shadow: 0 0 .8rem .05rem #20c569;
  }
}

.float-effect {
  animation-name: animate-float;
  animation-duration: 8s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes animate-float {
  0% {
    transform: rotate(0)translate(0);
  }

  50% {
    transform: rotate(-.8deg)translate(.02rem, -.3rem);
  }

  100% {
    transform: rotate(1deg)translate(.07rem, .05rem);
  }
}

[data-wavy-underline], a.link--with-wave-underline {
  text-decoration: none;
  position: relative;
}

[data-wavy-underline].link--with-wave-underline--ready svg, a.link--with-wave-underline.link--with-wave-underline--ready svg {
  left: 50%;
  transform: translateX(-50%);
}

.link-wave-underline {
  width: 100%;
  height: .3rem;
  position: absolute;
  bottom: -2px;
  left: 0;
  right: 0;
}

.link-wave-underline svg {
  height: .3rem;
  position: absolute;
  inset: 0;
  overflow: hidden;
}

.header {
  z-index: 15;
  position: sticky;
  top: 0;
  left: 10px;
  right: 10px;
}

@media only screen and (min-width: 1024px) {
  .header {
    left: 30px;
    right: 30px;
  }
}

.header__logo {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (min-width: 1024px) {
  .header__logo {
    width: 30%;
  }
}

.header__logo svg {
  overflow: visible;
}

.header__menu-hamb {
  width: 16.5%;
  position: fixed;
  top: 10px;
  right: 10px;
}

@media only screen and (min-width: 1024px) {
  .header__menu-hamb {
    display: none;
  }
}

.header__nav {
  display: none;
  position: absolute;
  top: 5px;
  right: 5px;
}

@media only screen and (min-width: 1024px) {
  .header__nav {
    display: block;
    top: 15px;
    right: 15px;
  }
}

.header__nav ul {
  display: flex;
}

.header__nav li {
  padding-left: .5em;
}

.header__nav li a .link-wave-underline {
  opacity: 0;
}

.header__nav li a.active .link-wave-underline, .header__nav li a:hover .link-wave-underline {
  opacity: 1;
}

body.menu-mobile-open {
  overflow: hidden;
}

.menu-mobile {
  background-color: #000;
  display: none;
  position: fixed;
  inset: 0;
}

.menu-mobile-open .menu-mobile {
  display: block;
}

.menu-mobile__close {
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: 10px;
  right: 10px;
}

.menu-mobile__close img {
  width: 100%;
  height: auto;
  display: block;
}

.menu-mobile__logo {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
}

@media only screen and (min-width: 1024px) {
  .menu-mobile__logo {
    width: 30%;
  }
}

.menu-mobile__logo svg {
  display: block;
  overflow: visible;
}

.menu-mobile__nav {
  text-align: center;
  position: relative;
  top: 35%;
  transform: translateY(-50%);
}

.menu-mobile__nav li + li {
  margin-top: .35em;
}

.menu-mobile__nav a {
  color: #6aa0ff;
}

.menu-mobile * {
  font-size: 5rem;
  line-height: .85;
}

.menu-mobile a {
  text-decoration: none;
}

.menu-mobile__the-deep-end {
  width: 40%;
  position: absolute;
  bottom: 3vh;
  left: 50%;
  transform: translateX(-50%);
}

.the-deep-end-logo {
  z-index: 2;
  width: 10%;
  display: none;
  position: fixed;
  top: calc(100% - 13vw);
  left: 1%;
}

@media only screen and (min-width: 1024px) {
  .the-deep-end-logo {
    display: block;
  }
}

.the-deep-end-logo svg path {
  fill: #06cad5;
  transition: fill .3s ease-in-out;
}

.the-deep-end-logo:hover svg path {
  fill: #005b53;
}

.copyright {
  width: calc(100% + 20px);
  margin-left: -10px;
  margin-right: -10px;
  padding-top: 2.5rem;
  padding-bottom: .5rem;
}

@media only screen and (min-width: 1024px) {
  .copyright {
    width: calc(100% + 60px);
    margin-left: -30px;
    margin-right: -30px;
    padding-top: 4rem;
  }
}

.footer {
  z-index: 2;
  position: relative;
}

.footer__ticker-links {
  background: linear-gradient(#005b53 0%, #6aa0ff 50%, #005b53 100%);
}

.footer__big-ticker.ticker {
  border-top: 1px solid #20c569;
  height: 4.9304rem;
  padding-top: .65rem;
}

@media only screen and (min-width: 1024px) {
  .footer__big-ticker.ticker {
    height: 7.99733rem;
  }
}

.footer__big-ticker--variant.ticker {
  height: 4.9672rem;
  padding-top: 0;
  padding-bottom: 1rem;
}

@media only screen and (min-width: 1024px) {
  .footer__big-ticker--variant.ticker {
    height: 7.99733rem;
  }
}

.footer .ticker-text, .footer .ticker-text * {
  transition: text-shadow .2s ease-out;
}

.footer a.ticker:hover .ticker-text, .footer a.ticker:hover .ticker-text * {
  text-shadow: 0 0 1rem #d9ff56;
}

.footer a.ticker:hover .ticker-text.text--green, .footer a.ticker:hover .ticker-text .text--green {
  text-shadow: 0 0 1rem #20c569;
}

.footer a.ticker:hover .ticker-text.text--green-dark, .footer a.ticker:hover .ticker-text .text--green-dark {
  text-shadow: 0 0 1rem #005b53;
}

.footer a.ticker:hover .ticker-text.text--blue, .footer a.ticker:hover .ticker-text .text--blue {
  text-shadow: 0 0 1rem #6aa0ff;
}

.footer a.ticker:hover .ticker-text.text--cyan, .footer a.ticker:hover .ticker-text .text--cyan {
  text-shadow: 0 0 1rem #06cad5;
}

.footer a.ticker:hover .ticker__image img {
  box-shadow: 0 0 1rem .25rem #20c569;
}

.footer .ticker__image {
  width: auto;
  height: 3.676rem;
  display: inline-block;
}

@media only screen and (min-width: 1024px) {
  .footer .ticker__image {
    height: 5.99733rem;
  }
}

.footer .ticker__image img {
  border-color: #20c569;
  width: auto;
  height: 100%;
  transition: box-shadow .2s ease-out;
  box-shadow: 0 0 .3rem .1rem #20c569;
}

.footer .footer__big-ticker--variant .ticker__image {
  margin-top: .5rem;
}

@media only screen and (min-width: 1024px) {
  .footer .footer__big-ticker--variant .ticker__image {
    margin-top: 1rem;
  }
}

.big-button {
  text-transform: uppercase;
  letter-spacing: .08rem;
  color: #d9ff56;
  text-align: center;
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid;
  border-radius: .7rem;
  width: 100%;
  padding: 1.1rem 1.5rem 0;
  font-size: 3.33rem;
  line-height: .85;
  text-decoration: none;
  display: block;
}

@media only screen and (min-width: 1024px) {
  .big-button {
    border-radius: .4rem;
    padding: 1.5rem 1.5rem 0;
    font-size: 5rem;
    line-height: .7;
  }
}

.big-button:hover {
  color: #20c569;
}

.ticker {
  width: 100%;
  height: 5.22rem;
  display: block;
  position: relative;
  overflow: hidden;
  transform: translateZ(0);
}

@media only screen and (min-width: 1024px) {
  .ticker {
    height: 8.53333rem;
  }
}

.ticker--copyright {
  height: .61rem;
}

@media only screen and (min-width: 1024px) {
  .ticker--copyright {
    height: 1rem;
  }
}

.ticker__content {
  align-items: flex-start;
  font-size: 0;
  display: flex;
  position: absolute;
}

.ticker__text {
  align-items: flex-start;
  display: flex;
}

.ticker__text > * {
  width: max-content;
  padding: 0 .15em;
}

.ticker__text--move {
  will-change: transform;
  animation: 18s linear infinite ticker-move;
}

.ticker--right .ticker__text--move {
  animation-direction: reverse;
}

.ticker__text span span {
  position: relative;
}

.case-study-card {
  text-align: center;
}

.case-study-card a, .case-study-card a:hover, .case-study-card a:hover * {
  text-decoration: none;
}

.case-study-card + .case-study-card {
  margin-top: 1rem;
}

@media only screen and (min-width: 1024px) {
  .case-study-card + .case-study-card {
    margin-top: 2.5rem;
  }
}

.case-study-card--hidden {
  display: none;
}

.case-study-card h3 + p {
  margin-top: 0;
}

.case-study-card__image {
  margin-bottom: .3rem;
}

@media only screen and (min-width: 1024px) {
  .case-study-card__image {
    margin-bottom: .5rem;
  }
}

.case-study-card__image img {
  border-color: #20c569;
}

.case-study-card__image:hover img {
  border-color: #d9ff56;
  box-shadow: 0 0 1rem .25rem #d9ff56;
}

.case-study-card__cat, .case-study-card__cat * {
  color: #20c569;
}

.credits-line {
  display: flex;
}

.credits-line, .credits-line * {
  line-height: 1.3;
}

.credits-line__text-left, .credits-line__text-right {
  color: #20c569;
  flex: 1 0 auto;
}

.credits-line__dots {
  flex: 0 auto;
  margin: 0 5px;
  overflow: hidden;
}

.credits-line__dots:before {
  white-space: nowrap;
  text-overflow: clip;
  color: #6aa0ff;
  content: "...................." "...................." "...................." "....................";
  display: block;
  overflow: hidden;
}

.image-grid__grid {
  flex-wrap: wrap;
  display: flex;
}

.image-grid__image {
  flex-basis: 50%;
}

.image-grid__image:nth-child(2), .image-grid__image:nth-child(4) {
  margin-left: -1px;
}

.image-grid__image:nth-child(3), .image-grid__image:nth-child(4) {
  margin-top: -1px;
}

.image-grid__caption {
  text-align: center;
}

.image-grid__caption .figcaption {
  margin-top: .5em;
}

body.homepage {
  background: linear-gradient(#20c569 0% 30vh, #6aa0ff 50vh, #005b53 50%, #000 75%);
}

@media only screen and (min-width: 1024px) {
  body.homepage {
    background: linear-gradient(#20c569 0% 130vh, #6aa0ff 230vh, #005b53 40%, #000 75%);
  }
}

body.homepage .header {
  position: fixed;
  left: 0;
  right: 0;
}

body.homepage .header__logo, body.homepage .the-deep-end-logo, .hero-home__img--desktop {
  display: none;
}

@media only screen and (min-width: 1024px) {
  .hero-home__img--desktop {
    display: block;
  }

  .hero-home__img--mobile {
    display: none;
  }
}

.hero-home__logo {
  z-index: 10;
  width: 100%;
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

@media only screen and (min-width: 1024px) {
  .hero-home__logo {
    width: 90%;
    margin-top: 1.85rem;
  }
}

.hero-home__logo svg {
  overflow: visible;
}

.hero-home__the-deep-end {
  width: 20%;
  display: none;
  position: fixed;
  top: calc(100% - 13vw);
  left: 1%;
}

@media only screen and (min-width: 1024px) {
  .hero-home__the-deep-end {
    display: block;
  }
}

.hero-home__the-deep-end svg path {
  fill: #06cad5;
  transition: fill .3s ease-in-out;
}

.hero-home__the-deep-end:hover svg path {
  fill: #005b53;
}

.hero-home__logo-animated {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.hero-home__logo-animated--fixed {
  top: 0;
  bottom: initial;
  position: fixed;
}

.section.section--services {
  margin-top: 1rem;
}

@media only screen and (min-width: 1024px) {
  .section.section--services {
    margin-top: 3rem;
  }
}

.section.section--guarantee {
  margin-top: 0;
}

@media only screen and (min-width: 1024px) {
  .section.section--guarantee {
    margin-top: 3rem;
  }
}

.guarantee {
  margin-top: 1.5rem;
}

.guarantee__hero {
  position: relative;
}

.guarantee__hero-img {
  padding-bottom: 4.5rem;
}

@media only screen and (min-width: 1024px) {
  .guarantee__hero-img {
    margin-bottom: -1rem;
    padding-bottom: 7.5rem;
  }
}

.guarantee__balloon {
  position: absolute;
  bottom: 0;
}

.guarantee__balloon--desktop {
  width: 90%;
  display: none;
  left: -25%;
}

@media only screen and (min-width: 1024px) {
  .guarantee__balloon--desktop {
    display: block;
  }
}

.guarantee__balloon--mobile {
  width: 95%;
  right: -7%;
}

@media only screen and (min-width: 1024px) {
  .guarantee__balloon--mobile {
    display: none;
  }
}

.collaborators__heading {
  width: calc(135% + 20px);
  margin-bottom: 1rem;
  margin-left: -10px;
  margin-right: -10px;
}

@media only screen and (min-width: 1024px) {
  .collaborators__heading {
    width: calc(100% + 60px);
    margin-left: -30px;
    margin-right: -30px;
  }
}

.collaborators__heading text {
  letter-spacing: .1em;
  text-shadow: 0 0 .8rem #06cad5;
}

.collaborators__collaborator:not(:last-child) {
  margin-bottom: .4rem;
}

@media only screen and (min-width: 1024px) {
  .collaborators__collaborator:not(:last-child) {
    margin-bottom: 0;
  }
}

.case-studies .section--case-studies {
  min-height: 70vh;
  margin-top: 2rem;
}

@media only screen and (min-width: 1024px) {
  .case-studies .section--case-studies {
    margin-top: 3.5rem;
  }
}

.case-studies .section--case-studies .row + .row {
  margin-top: 1rem;
}

.case-study-filters {
  z-index: 2;
  text-align: center;
}

.case-study-filters, .case-study-filters * {
  color: #20c569;
}

.case-studies .case-study-filters {
  border-top: 1px solid;
  border-bottom: 1px solid;
  width: calc(100% + 20px);
  margin-left: -10px;
  margin-right: -10px;
  padding-top: 6.5px;
  padding-bottom: 5px;
}

@media only screen and (min-width: 1024px) {
  .case-studies .case-study-filters {
    border-top: 0;
    border-bottom: 0;
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    padding: 0;
  }
}

.case-study-filters__text-filter {
  display: none;
}

@media only screen and (min-width: 1024px) {
  .case-study-filters__text-filter {
    display: initial;
  }
}

.case-study-filters__filter {
  margin-left: .5em;
  padding-left: 1em;
  position: relative;
}

.case-study-filters__filter:before {
  content: "";
  background: url("high-dive-star.94590c7f.svg") 0 0 no-repeat;
  width: 1.6em;
  height: 1.6em;
  position: absolute;
  top: -.4em;
  left: -.4em;
}

.case-study-filters__filter.filter-case-studies--active {
  color: #d9ff56;
}

@media only screen and (min-width: 1024px) {
  .case-study-filters__filter:hover {
    cursor: pointer;
    color: #d9ff56;
  }
}

.floating-filters-trigger {
  background: none;
  width: 100%;
  height: 1px;
}

.floating-filters {
  z-index: 17;
  opacity: 0;
  pointer-events: none;
  transition: opacity .3s ease-out;
  display: none;
  position: fixed;
  top: 10px;
  left: 10px;
}

@media only screen and (min-width: 1024px) {
  .floating-filters {
    display: initial;
    top: 30px;
    left: 30px;
  }
}

.show-floating-filters .floating-filters {
  opacity: 1;
  pointer-events: all;
}

.floating-filters__filter {
  color: #20c569;
  padding-left: 1.3em;
  position: relative;
}

.floating-filters__filter .link-wave-underline {
  bottom: -3px;
}

.floating-filters__filter:before {
  content: "";
  background: url("high-dive-star.94590c7f.svg") 0 0 no-repeat;
  width: 1.6em;
  height: 1.6em;
  position: absolute;
  top: -.3em;
  left: -.2em;
}

.floating-filters__filter:hover {
  cursor: pointer;
  color: #d9ff56;
}

.floating-filters__filter.filter-case-studies--active {
  color: #d9ff56;
}

.the-deep-end {
  background: #6aa0ff;
  overflow-x: hidden;
}

.the-deep-end .footer__ticker-links {
  background: linear-gradient(#005b5370 0%, #6aa0ff70 50%, #005b5370 100%);
}

.the-deep-end .the-deep-end-logo {
  pointer-events: none;
  top: 2%;
}

.the-deep-end .the-deep-end-logo svg path {
  fill: #005b53 !important;
}

.the-deep-end-grid {
  opacity: 0;
}

.msnry-loaded .the-deep-end-grid {
  opacity: 1;
}

.the-deep-end-grid__bg {
  background-image: url("temp-bg.51ec615d.png");
  background-position: center;
  background-size: cover;
  position: fixed;
  inset: 0;
}

.the-deep-end-grid__image {
  width: 50%;
  padding: 1.5rem .5rem;
}

@media only screen and (min-width: 1024px) {
  .the-deep-end-grid__image {
    width: 33.3333%;
    padding: 1.5rem;
  }
}

.the-deep-end-grid__image:nth-child(2) {
  padding-top: 5rem;
}

.random-obj-list {
  display: none;
}

.float-objects {
  pointer-events: none;
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.float-objects__image {
  position: absolute;
  left: 0;
  right: 0;
}

.float-objects__image img {
  width: 30%;
  height: auto;
}

.the-deep-end-grid__image:nth-child(15n+1) {
  animation-name: the-deep-grid-float-animate1;
  animation-duration: 24s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes the-deep-grid-float-animate1 {
  0% {
    transform: rotate(0)translate(0);
  }

  25% {
    transform: rotate(2deg)translate(-.05rem);
  }

  50% {
    transform: rotate(2deg)translate(-.05rem, -.15rem);
  }

  75% {
    transform: rotate(1deg)translate(-.05rem);
  }

  100% {
    transform: rotate(0)translate(-.05rem, -.15rem);
  }
}

.the-deep-end-grid__image:nth-child(15n+2) {
  animation-name: the-deep-grid-float-animate2;
  animation-duration: 21s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes the-deep-grid-float-animate2 {
  0% {
    transform: rotate(0)translate(0);
  }

  25% {
    transform: rotate(2deg)translate(-.05rem);
  }

  50% {
    transform: rotate(0)translate(.95rem, -.15rem);
  }

  75% {
    transform: rotate(2deg)translate(-.05rem);
  }

  100% {
    transform: rotate(1deg)translate(-.05rem, .85rem);
  }
}

.the-deep-end-grid__image:nth-child(15n+3) {
  animation-name: the-deep-grid-float-animate3;
  animation-duration: 24s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes the-deep-grid-float-animate3 {
  0% {
    transform: rotate(0)translate(0);
  }

  25% {
    transform: rotate(0)translate(-.05rem);
  }

  50% {
    transform: rotate(2deg)translate(-.05rem, -.15rem);
  }

  75% {
    transform: rotate(1deg)translate(-.05rem);
  }

  100% {
    transform: rotate(1deg)translate(-.05rem, -.15rem);
  }
}

.the-deep-end-grid__image:nth-child(15n+4) {
  animation-name: the-deep-grid-float-animate4;
  animation-duration: 23s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes the-deep-grid-float-animate4 {
  0% {
    transform: rotate(0)translate(0);
  }

  25% {
    transform: rotate(0)translate(.95rem);
  }

  50% {
    transform: rotate(2deg)translate(-.05rem, -.15rem);
  }

  75% {
    transform: rotate(2deg)translate(-.05rem);
  }

  100% {
    transform: rotate(1deg)translate(-.05rem, .85rem);
  }
}

.the-deep-end-grid__image:nth-child(15n+5) {
  animation-name: the-deep-grid-float-animate5;
  animation-duration: 22s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes the-deep-grid-float-animate5 {
  0% {
    transform: rotate(0)translate(0);
  }

  25% {
    transform: rotate(2deg)translate(-.05rem);
  }

  50% {
    transform: rotate(-1deg)translate(.95rem, -.15rem);
  }

  75% {
    transform: rotate(0)translate(-.05rem);
  }

  100% {
    transform: rotate(2deg)translate(-.05rem, .85rem);
  }
}

.the-deep-end-grid__image:nth-child(15n+6) {
  animation-name: the-deep-grid-float-animate6;
  animation-duration: 24s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes the-deep-grid-float-animate6 {
  0% {
    transform: rotate(0)translate(0);
  }

  25% {
    transform: rotate(0)translate(-.05rem);
  }

  50% {
    transform: rotate(-1deg)translate(-.05rem, -.15rem);
  }

  75% {
    transform: rotate(-2deg)translate(-.05rem);
  }

  100% {
    transform: rotate(-1deg)translate(-.05rem, -.15rem);
  }
}

.the-deep-end-grid__image:nth-child(15n+7) {
  animation-name: the-deep-grid-float-animate7;
  animation-duration: 22s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes the-deep-grid-float-animate7 {
  0% {
    transform: rotate(0)translate(0);
  }

  25% {
    transform: rotate(1deg)translate(-.05rem);
  }

  50% {
    transform: rotate(0)translate(.95rem, -.15rem);
  }

  75% {
    transform: rotate(0)translate(-.05rem);
  }

  100% {
    transform: rotate(-1deg)translate(.95rem, -.15rem);
  }
}

.the-deep-end-grid__image:nth-child(15n+8) {
  animation-name: the-deep-grid-float-animate8;
  animation-duration: 22s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes the-deep-grid-float-animate8 {
  0% {
    transform: rotate(0)translate(0);
  }

  25% {
    transform: rotate(2deg)translate(-.05rem);
  }

  50% {
    transform: rotate(-1deg)translate(-.05rem, -.15rem);
  }

  75% {
    transform: rotate(1deg)translate(-.05rem);
  }

  100% {
    transform: rotate(1deg)translate(-.05rem, -.15rem);
  }
}

.the-deep-end-grid__image:nth-child(15n+9) {
  animation-name: the-deep-grid-float-animate9;
  animation-duration: 22s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes the-deep-grid-float-animate9 {
  0% {
    transform: rotate(0)translate(0);
  }

  25% {
    transform: rotate(1deg)translate(.95rem);
  }

  50% {
    transform: rotate(-1deg)translate(-.05rem, -.15rem);
  }

  75% {
    transform: rotate(1deg)translate(-.05rem, 1rem);
  }

  100% {
    transform: rotate(-2deg)translate(-.05rem, -.15rem);
  }
}

.the-deep-end-grid__image:nth-child(15n+10) {
  animation-name: the-deep-grid-float-animate10;
  animation-duration: 25s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes the-deep-grid-float-animate10 {
  0% {
    transform: rotate(0)translate(0);
  }

  25% {
    transform: rotate(-1deg)translate(-.05rem);
  }

  50% {
    transform: rotate(-1deg)translate(-.05rem, -.15rem);
  }

  75% {
    transform: rotate(1deg)translate(-.05rem);
  }

  100% {
    transform: rotate(2deg)translate(-.05rem, -.15rem);
  }
}

.the-deep-end-grid__image:nth-child(15n+11) {
  animation-name: the-deep-grid-float-animate11;
  animation-duration: 25s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes the-deep-grid-float-animate11 {
  0% {
    transform: rotate(0)translate(0);
  }

  25% {
    transform: rotate(0)translate(-.05rem);
  }

  50% {
    transform: rotate(-2deg)translate(-.05rem, -.15rem);
  }

  75% {
    transform: rotate(-1deg)translate(.95rem);
  }

  100% {
    transform: rotate(1deg)translate(-.05rem, -.15rem);
  }
}

.the-deep-end-grid__image:nth-child(15n+12) {
  animation-name: the-deep-grid-float-animate12;
  animation-duration: 24s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes the-deep-grid-float-animate12 {
  0% {
    transform: rotate(0)translate(0);
  }

  25% {
    transform: rotate(-2deg)translate(-.05rem);
  }

  50% {
    transform: rotate(1deg)translate(-.05rem, -.15rem);
  }

  75% {
    transform: rotate(1deg)translate(-.05rem);
  }

  100% {
    transform: rotate(2deg)translate(.95rem, -.15rem);
  }
}

.the-deep-end-grid__image:nth-child(15n+13) {
  animation-name: the-deep-grid-float-animate13;
  animation-duration: 23s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes the-deep-grid-float-animate13 {
  0% {
    transform: rotate(0)translate(0);
  }

  25% {
    transform: rotate(0)translate(-.05rem, 1rem);
  }

  50% {
    transform: rotate(-2deg)translate(-.05rem, -.15rem);
  }

  75% {
    transform: rotate(0)translate(-.05rem);
  }

  100% {
    transform: rotate(2deg)translate(-.05rem, .85rem);
  }
}

.the-deep-end-grid__image:nth-child(15n+14) {
  animation-name: the-deep-grid-float-animate14;
  animation-duration: 21s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes the-deep-grid-float-animate14 {
  0% {
    transform: rotate(0)translate(0);
  }

  25% {
    transform: rotate(1deg)translate(-.05rem);
  }

  50% {
    transform: rotate(0)translate(-.05rem, -.15rem);
  }

  75% {
    transform: rotate(-1deg)translate(-.05rem);
  }

  100% {
    transform: rotate(-2deg)translate(-.05rem, -.15rem);
  }
}

.the-deep-end-grid__image:nth-child(15n+15) {
  animation-name: the-deep-grid-float-animate15;
  animation-duration: 21s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes the-deep-grid-float-animate15 {
  0% {
    transform: rotate(0)translate(0);
  }

  25% {
    transform: rotate(1deg)translate(-.05rem);
  }

  50% {
    transform: rotate(2deg)translate(.95rem, -.15rem);
  }

  75% {
    transform: rotate(-1deg)translate(-.05rem);
  }

  100% {
    transform: rotate(0)translate(-.05rem, -.15rem);
  }
}

.floating-animation:nth-child(15n+1) img {
  animation-name: floating-animation-1;
  animation-duration: 24s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes floating-animation-1 {
  0% {
    transform: rotate(0)translate(0);
  }

  25% {
    transform: rotate(10deg)translate(-.5rem);
  }

  50% {
    transform: rotate(-9deg)translate(.5rem, .5rem);
  }

  75% {
    transform: rotate(9deg)translate(-.5rem, 1rem);
  }

  100% {
    transform: rotate(4deg)translate(.5rem, -.5rem);
  }
}

.floating-animation:nth-child(15n+2) img {
  animation-name: floating-animation-2;
  animation-duration: 25s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes floating-animation-2 {
  0% {
    transform: rotate(0)translate(0);
  }

  25% {
    transform: rotate(-8deg)translate(.5rem);
  }

  50% {
    transform: rotate(1deg)translate(.5rem, -.5rem);
  }

  75% {
    transform: rotate(-10deg)translate(.5rem);
  }

  100% {
    transform: rotate(-6deg)translate(.5rem, -.5rem);
  }
}

.floating-animation:nth-child(15n+3) img {
  animation-name: floating-animation-3;
  animation-duration: 23s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes floating-animation-3 {
  0% {
    transform: rotate(0)translate(0);
  }

  25% {
    transform: rotate(7deg)translate(-.5rem);
  }

  50% {
    transform: rotate(4deg)translate(-.5rem, -.5rem);
  }

  75% {
    transform: rotate(-5deg)translate(-.5rem, 1rem);
  }

  100% {
    transform: rotate(5deg)translate(.5rem, -.5rem);
  }
}

.floating-animation:nth-child(15n+4) img {
  animation-name: floating-animation-4;
  animation-duration: 25s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes floating-animation-4 {
  0% {
    transform: rotate(0)translate(0);
  }

  25% {
    transform: rotate(8deg)translate(-.5rem, 1rem);
  }

  50% {
    transform: rotate(10deg)translate(-.5rem, .5rem);
  }

  75% {
    transform: rotate(-3deg)translate(.5rem);
  }

  100% {
    transform: rotate(-10deg)translate(.5rem, .5rem);
  }
}

.floating-animation:nth-child(15n+5) img {
  animation-name: floating-animation-5;
  animation-duration: 23s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes floating-animation-5 {
  0% {
    transform: rotate(0)translate(0);
  }

  25% {
    transform: rotate(10deg)translate(-.5rem);
  }

  50% {
    transform: rotate(5deg)translate(.5rem, -.5rem);
  }

  75% {
    transform: rotate(4deg)translate(.5rem);
  }

  100% {
    transform: rotate(-8deg)translate(.5rem, -.5rem);
  }
}

.floating-animation:nth-child(15n+6) img {
  animation-name: floating-animation-6;
  animation-duration: 25s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes floating-animation-6 {
  0% {
    transform: rotate(0)translate(0);
  }

  25% {
    transform: rotate(-2deg)translate(.5rem);
  }

  50% {
    transform: rotate(9deg)translate(.5rem, -.5rem);
  }

  75% {
    transform: rotate(-2deg)translate(.5rem, 1rem);
  }

  100% {
    transform: rotate(1deg)translate(.5rem, .5rem);
  }
}

.floating-animation:nth-child(15n+7) img {
  animation-name: floating-animation-7;
  animation-duration: 24s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes floating-animation-7 {
  0% {
    transform: rotate(0)translate(0);
  }

  25% {
    transform: rotate(10deg)translate(-.5rem);
  }

  50% {
    transform: rotate(9deg)translate(-.5rem, -.5rem);
  }

  75% {
    transform: rotate(9deg)translate(.5rem);
  }

  100% {
    transform: rotate(1deg)translate(.5rem, -.5rem);
  }
}

.floating-animation:nth-child(15n+8) img {
  animation-name: floating-animation-8;
  animation-duration: 21s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes floating-animation-8 {
  0% {
    transform: rotate(0)translate(0);
  }

  25% {
    transform: rotate(-6deg)translate(-.5rem, 1rem);
  }

  50% {
    transform: rotate(9deg)translate(.5rem, -.5rem);
  }

  75% {
    transform: rotate(-1deg)translate(-.5rem, 1rem);
  }

  100% {
    transform: rotate(2deg)translate(.5rem, -.5rem);
  }
}

.floating-animation:nth-child(15n+9) img {
  animation-name: floating-animation-9;
  animation-duration: 23s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes floating-animation-9 {
  0% {
    transform: rotate(0)translate(0);
  }

  25% {
    transform: rotate(1deg)translate(-.5rem);
  }

  50% {
    transform: rotate(3deg)translate(-.5rem, -.5rem);
  }

  75% {
    transform: rotate(0)translate(-.5rem, 1rem);
  }

  100% {
    transform: rotate(-8deg)translate(-.5rem, -.5rem);
  }
}

.floating-animation:nth-child(15n+10) img {
  animation-name: floating-animation-10;
  animation-duration: 23s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes floating-animation-10 {
  0% {
    transform: rotate(0)translate(0);
  }

  25% {
    transform: rotate(-7deg)translate(.5rem);
  }

  50% {
    transform: rotate(4deg)translate(.5rem, -.5rem);
  }

  75% {
    transform: rotate(7deg)translate(.5rem, 1rem);
  }

  100% {
    transform: rotate(-1deg)translate(-.5rem, -.5rem);
  }
}

.floating-animation:nth-child(15n+11) img {
  animation-name: floating-animation-11;
  animation-duration: 25s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes floating-animation-11 {
  0% {
    transform: rotate(0)translate(0);
  }

  25% {
    transform: rotate(-1deg)translate(-.5rem);
  }

  50% {
    transform: rotate(-5deg)translate(.5rem, -.5rem);
  }

  75% {
    transform: rotate(4deg)translate(.5rem);
  }

  100% {
    transform: rotate(0)translate(-.5rem, -.5rem);
  }
}

.floating-animation:nth-child(15n+12) img {
  animation-name: floating-animation-12;
  animation-duration: 22s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes floating-animation-12 {
  0% {
    transform: rotate(0)translate(0);
  }

  25% {
    transform: rotate(3deg)translate(-.5rem);
  }

  50% {
    transform: rotate(-8deg)translate(-.5rem, .5rem);
  }

  75% {
    transform: rotate(5deg)translate(-.5rem);
  }

  100% {
    transform: rotate(-6deg)translate(.5rem, -.5rem);
  }
}

.floating-animation:nth-child(15n+13) img {
  animation-name: floating-animation-13;
  animation-duration: 23s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes floating-animation-13 {
  0% {
    transform: rotate(0)translate(0);
  }

  25% {
    transform: rotate(1deg)translate(-.5rem);
  }

  50% {
    transform: rotate(-8deg)translate(.5rem, -.5rem);
  }

  75% {
    transform: rotate(3deg)translate(-.5rem, 1rem);
  }

  100% {
    transform: rotate(1deg)translate(-.5rem, -.5rem);
  }
}

.floating-animation:nth-child(15n+14) img {
  animation-name: floating-animation-14;
  animation-duration: 24s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes floating-animation-14 {
  0% {
    transform: rotate(0)translate(0);
  }

  25% {
    transform: rotate(10deg)translate(.5rem);
  }

  50% {
    transform: rotate(-1deg)translate(-.5rem, -.5rem);
  }

  75% {
    transform: rotate(7deg)translate(-.5rem);
  }

  100% {
    transform: rotate(-1deg)translate(-.5rem, .5rem);
  }
}

.floating-animation:nth-child(15n+15) img {
  animation-name: floating-animation-15;
  animation-duration: 24s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes floating-animation-15 {
  0% {
    transform: rotate(0)translate(0);
  }

  25% {
    transform: rotate(9deg)translate(-.5rem);
  }

  50% {
    transform: rotate(-10deg)translate(.5rem, .5rem);
  }

  75% {
    transform: rotate(-7deg)translate(.5rem);
  }

  100% {
    transform: rotate(9deg)translate(-.5rem, .5rem);
  }
}

body.page-404 {
  background-image: url("404-bg.cc2982dc.gif");
  background-repeat: repeat;
  background-size: 200%;
  animation-name: background-repeat;
  animation-duration: 2s;
  animation-timing-function: step-end;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

.text-404 {
  width: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media only screen and (min-width: 641px) {
  .text-404 {
    width: 70%;
  }
}

@media only screen and (min-width: 1024px) {
  .text-404 {
    width: 65%;
  }
}

@media only screen and (min-width: 1440px) {
  .text-404 {
    width: 55%;
  }
}

.text-404, .text-404 * {
  color: #d9ff56;
  text-align: center;
}

@keyframes background-repeat {
  0% {
    background-position: 0 0;
    background-size: 200%;
  }

  25% {
    background-position: center;
    background-size: 100%;
  }

  75% {
    background-position: center;
    background-size: 50%;
  }
}

/*# sourceMappingURL=404.c22ba02b.css.map */
