body {
  background-color: $color-blue;
  background: linear-gradient(180deg, $color-blue 0%, $color-green-dark 25vh, black 45%);
  background-repeat: no-repeat;

  @media #{$breakpoint-md-up} {
    background: linear-gradient(180deg, $color-blue 0%, $color-green-dark 65vh, black 60%);
  }
}

.page-content {
  overflow-x: hidden;
  padding: 0 $container-pad--mobile;

  @media #{$breakpoint-md-up} {
    padding: 0 $container-pad;
  }
}

figure {
  text-align: center;

  video,
  img {
    border: 1px solid;
    width: 100%;
    height: auto;
    border-radius: $border-radius--mobile;
    overflow: hidden;

    @media #{$breakpoint-md-up} {
      border-radius: $border-radius;
    }
  }

  figcaption {
    margin-top: 0.5em;
  }
}

.center-text {
  text-align: center;
}

.center-text--desktop {
  @media #{$breakpoint-md-up} {
    text-align: center;
  }
}

.center-text--mobile {
  text-align: center;
  @media #{$breakpoint-md-up} {
    text-align: left;
  }
}

.row + .row {
  margin-top: 1.5rem;
  @media #{$breakpoint-md-up} {
    margin-top: 2rem;
  }
}

[class^="col-xs-"] + [class^="col-xs-"] {
  margin-top: 1.5rem;
  @media #{$breakpoint-md-up} {
    margin-top: 0;
  }

}

.section--first {
  margin-top: 2em;
  @media #{$breakpoint-md-up} {
    margin-top: 3.3em;
  }
}

.section + .section {
  margin-top: 2rem;
  @media #{$breakpoint-md-up} {
    margin-top: 3rem;
  }
}

.section--bg-wavy {
  background-image: url('/assets/img/graphics/wavy-bg.svg');
  background-position: center;
  background-size: cover;
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
  border-top: 1px solid $color-green;
  border-bottom: 1px solid $color-green;
  width: calc(100% + #{$container-pad--mobile}*2);
  margin-left: -$container-pad--mobile;
  margin-right: -$container-pad--mobile;
  padding-left: $container-pad--mobile;
  padding-right: $container-pad--mobile;

  @media #{$breakpoint-md-up} {
    width: calc(100% + #{$container-pad}*2);
    margin-left: -$container-pad;
    margin-right: -$container-pad;
    padding-left: $container-pad;
    padding-right: $container-pad;
  }
}

.glow-block {
  border-radius: $border-radius--mobile;
  border: 1px solid $color-green;
  box-shadow: 0 0 0.6rem 0.03rem $color-green;
  padding: $container-pad--mobile;
  padding-top: 2rem;
  padding-bottom: 2rem;
  background-color: black;

  @media #{$breakpoint-md-up} {
    border-radius: $border-radius;
    padding: 3.5rem;
    padding-top: 1.5rem;
    padding-bottom: 4.5rem;
    box-shadow: 0 0 0.8rem 0.05rem $color-green;
  }
}


.float-effect {
  animation-name: animate-float;
  animation-duration: 8s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@keyframes animate-float {
  0%   { transform: rotate(0deg)  translate(0rem, 0rem); }
  50%  { transform: rotate(-0.8deg)  translate(0.02rem, -0.3rem); }
  100% { transform: rotate(1deg)  translate(0.07rem, 0.05rem); }
}

[data-wavy-underline],
a.link--with-wave-underline {
  position: relative;
  text-decoration: none;

  &.link--with-wave-underline--ready {
    svg {
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.link-wave-underline {
  position: absolute;
  width: 100%;
  height: 0.3rem;
  bottom: -2px;
  left: 0;
  right: 0;
  //DEBUG
  // outline: 1px solid red;

  svg {
    position: absolute;
    overflow: hidden;
    inset: 0;
    height: 0.3rem;

    //DEBUG
    // outline: 0.5px solid rgba(255,0,0,0.5);
    // overflow: visible;
  }
}
