.header {
  position: sticky;
  top: 0;
  left: $container-pad--mobile;
  right: $container-pad--mobile;
  z-index: 15;

  @media #{$breakpoint-md-up} {
    left: $container-pad;
    right: $container-pad;
  }

  &__logo {
    width: 50%;
    margin-left: auto;
    margin-right: auto;

    @media #{$breakpoint-md-up} {
      width: 30%;
    }

    svg {
      overflow: visible;
    }
  }

  &__menu-hamb {
    position: fixed;
    top: $container-pad--mobile;
    right: $container-pad--mobile;
    width: 16.5%;

    @media #{$breakpoint-md-up} {
      display: none;
    }
  }

  &__nav {
    position: absolute;
    right: 0;
    top: $container-pad--mobile/2;
    right: $container-pad--mobile/2;
    display: none;

    @media #{$breakpoint-md-up} {
      top: $container-pad/2;
      right: $container-pad/2;
      display: block;
    }

    ul {
      display: flex;
    }

    li {
      padding-left: 0.5em;

      a {
        .link-wave-underline {
          opacity: 0;
        }

        &.active .link-wave-underline {
          opacity: 1;
        }
        &:hover .link-wave-underline {
          opacity: 1;
        }
      }
    }
  }
}

body.menu-mobile-open {
  overflow: hidden;
}

.menu-mobile {
  position: fixed;
  inset: 0;
  background-color: black;
  display: none;

  .menu-mobile-open & {
    display: block;
  }

  &__close {
    position: absolute;
    top: $container-pad--mobile;
    right: $container-pad--mobile;
    width: 1.5rem;
    height: 1.5rem;

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  &__logo {
    width: 50%;
    margin-left: auto;
    margin-right: auto;

    @media #{$breakpoint-md-up} {
      width: 30%;
    }

    svg {
      overflow: visible;
      display: block;
    }
  }

  &__nav {
    text-align: center;
    position: relative;
    top: 35%;
    transform: translateY(-50%);

    li + li {
      margin-top: 0.35em;
    }

    a {
      color: $color-blue;
    }
  }


  * {
    font-size: 5rem;
    line-height: 0.85;
  }

  a {
    text-decoration: none;
  }

  &__the-deep-end {
    position: absolute;
    bottom: 3vh;
    width: 40%;
    left: 50%;
    transform: translateX(-50%);
  }
}


.the-deep-end-logo {
  position: fixed;
  top: calc(100% - 13vw);
  left: 1%;
  width: 10%;
  z-index: 2;
  display: none;

  @media #{$breakpoint-md-up} {
    display: block;
  }

  svg path {
    fill: $color-cyan;
    transition: fill 0.3s ease-in-out;
  }

  &:hover svg path {
    fill: $color-green-dark;
  }
}
