@use "sass:math";

// 1. ABSTRACTS

@import 'abstracts/fonts';
@import 'abstracts/variables';
@import 'abstracts/functions';
@import 'abstracts/mixins';

// 2. VENDORS

@import 'vendors';

// 3. VENDORS EXTENSIONS

@import 'vendors-extensions/flexbox';
@import 'vendors-extensions/plyr';

// 4. BASE

@import 'base/typography';
@import 'base/base';

// 5. LAYOUT

@import 'layout/grid';
@import 'layout/general';
@import 'layout/header';
@import 'layout/footer';

// 6. COMPONENTS

@import 'components/button';
@import 'components/ticker';
@import 'components/case-study-card';
@import 'components/credits';
@import 'components/image-grid';

// 7. PAGES

@import 'pages/homepage';
@import 'pages/about';
@import 'pages/case-studies';
@import 'pages/the-deep-end';
@import 'pages/404';
