body.homepage {
  background-color: $color-green;
  background: linear-gradient(180deg, $color-green 0%, $color-green 30vh, $color-blue 50vh, $color-green-dark 50%, black 75%);

  @media #{$breakpoint-md-up} {
    background: linear-gradient(180deg, $color-green 0%, $color-green 130vh, $color-blue 230vh, $color-green-dark 40%, black 75%);
  }
}

body.homepage {
  .header {
    position: fixed;
    left: 0;
    right: 0;

    &__logo {
      display: none;
    }
  }

  .the-deep-end-logo {
    display: none;
  }
}

.hero-home {
  &__img {
    &--desktop {
      display: none;
      @media #{$breakpoint-md-up} {
        display: block;
      }
    }
    &--mobile {
      @media #{$breakpoint-md-up} {
        display: none;
      }
    }
  }

  &__logo {
    position: relative;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    z-index: 10;
    margin-top: $container-pad--mobile;

    @media #{$breakpoint-md-up} {
      margin-top: 1.85rem;
      width: 90%;
    }

    svg {
      overflow: visible;
    }
  }

  &__the-deep-end {
    position: fixed;
    top: calc(100% - 13vw);
    left: 1%;
    width: 20%; //10%
    display: none;

    @media #{$breakpoint-md-up} {
      display: block;
    }

    svg path {
      fill: $color-cyan;
      transition: fill 0.3s ease-in-out;
    }

    &:hover svg path {
      fill: $color-green-dark;
    }
  }

  &__logo-animated {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;

    &--fixed {
      position: fixed;
      top: 0;
      bottom: initial;
    }
  }
}
