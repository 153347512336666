.case-study-card {
  text-align: center;

  a, a:hover, a:hover * {
    text-decoration: none;
  }

  & + & {
    margin-top: 1rem;
    @media #{$breakpoint-md-up} {
      margin-top: 2.5rem;
    }
  }

  &--hidden {
    display: none;
  }

  h3 + p {
    margin-top: 0;
  }

  &__image {
    margin-bottom: 0.3rem;
    @media #{$breakpoint-md-up} {
      margin-bottom: 0.5rem;
    }

    img {
      border-color: $color-green;
    }

    &:hover img {
      border-color: $color-yellow;
      box-shadow: 0 0 1rem 0.25rem $color-yellow;
    }
  }

  &__cat {
    @extend .body-text--variant;

    &, & * {
      color: $color-green;
    }
  }
}
