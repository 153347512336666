/* GRID */
$grid-columns: 24; // Set number of columns in the grid

$xs-max: 640px; // 40rem
$sm-max: 1023px; // 64rem
$md-max: 1439px; // 90rem
$lg-max: 1790px; // $lg-max: 1920px; // 120rem

/* GUTTER AND CONTAINER PAD */
/* gutter and container pad changes on sm */
$gutter-size: 0;
$gutter: 0 $gutter-size; // Set gutter size
// $container-pad: $gutter-size *4;

$gutter-size--mobile: 0;
$gutter--mobile: 0 $gutter-size--mobile; // Set gutter size

$container-pad--mobile: 10px;
$container-pad: 30px;

/* COLORS */
$color-green: #20c569;
$color-green-dark: #005b53;
$color-blue: #6aa0ff;
$color-cyan: #06cad5;
$color-yellow: #d9ff56;

$color-green--alpha: #20c56970;
$color-green-dark--alpha: #005b5370;
$color-blue--alpha: #6aa0ff70;
$color-cyan--alpha: #06cad570;
$color-yellow--alpha: #d9ff5670;

$border-radius--mobile: 0.4rem;
$border-radius: 0.7rem;


/* FONT SIZES */
$base-font-size-original: 30; //30pt indesign

$paragraph-text-size: 1rem; // 30pt Indesign 1280px / 30px - 1280px / 34px - 1440px

$h1-text-size: math.div(135, $base-font-size-original) * 1rem; // 135pt
$h2-text-size: math.div(135, $base-font-size-original) * 1rem; // 135pt
$h3-text-size: math.div(55, $base-font-size-original) * 1rem; // 55pt
$h4-text-size: math.div(55, $base-font-size-original) * 1rem; // 55pt
$h5-text-size: math.div(50, $base-font-size-original) * 1rem; // 50pt
$h6-text-size: math.div(35, $base-font-size-original) * 1rem; // 35pt

$caption-text-size: math.div(20, $base-font-size-original) * 1rem; // 20pt

$copyright-text-size: math.div(30, $base-font-size-original) * 1rem; // 30pt

$text-path-text-size: math.div(36, $base-font-size-original) * 1rem; // 36pt

$balloon-text-size: math.div(81, $base-font-size-original) * 1rem; // 81px

$player-text-size: math.div(20, $base-font-size-original) * 1rem; // 20pt

$big-button-text-size: math.div(150, $base-font-size-original) * 1rem; // 150px

$ticker-text-size: math.div(256, $base-font-size-original) * 1rem; // 256pt


$paragraph-text-size--variant-mobile: 0.81rem;

$h1-text-size--mobile: 2.66rem; // 55px / 18px
$h2-text-size--mobile: 2.66rem; // 48pt - 414px = 130pt - 1125px -> 48px / 18px
$h3-text-size--mobile: 1.66rem; // 30px / 18px
$h4-text-size--mobile: 1.36rem; // 24.5px / 18px
$h6-text-size--mobile: 1rem; // 18px

$copyright-text-size--mobile: 0.61rem; // 12px

$text-path-text-size--mobile: 2.3rem; // 41px

$balloon-text-size--mobile: 1.94rem; // 35px

$player-text-size--mobile: 0.6rem; // 11pt

$big-button-text-size--mobile: 3.33rem; // 60px

$ticker-text-size--mobile: 5.22rem; // 94px
