.image-grid {

  &__grid {
    display: flex;
    flex-wrap: wrap;
  }

  &__image {
    flex-basis: 50%;

    &:nth-child(2),
    &:nth-child(4) {
      margin-left: -1px;
    }

    &:nth-child(3),
    &:nth-child(4) {
      margin-top: -1px;
    }
  }

  &__caption {
    text-align: center;

    .figcaption {
      margin-top: 0.5em;
    }
  }
}
