p, h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  font-weight: normal;
}

ul, ol, li {
  margin: 0;
  padding: 0;
}

* {
  font-family: "ITC Serif Gothic Std";
  // 18px on 414px screen size
  // 34px on 1440px screen size
  //
  // m = (34-18)/(1440-414)
  // b = 18 * ((8/513) * 414)
  // y = 8/513 + 658/57
  // 100vw not 1 vw:
  // y = 800vw/513 + 658px/57
  font-size: max(calc(800vw/513 + 658px/57), 16px);
  line-height: 1.3;
  color: $color-yellow;
}

ul, ul *, ol, ol *, li, li *,
p, p *,
.body-text, .body-text * {
  font-family: "ITC Serif Gothic Std";
  font-size: $paragraph-text-size;
  letter-spacing: -0.01em;
  line-height: 1.2;

  @media #{$breakpoint-md-up} {
    letter-spacing: -0.025em;
    line-height: 1.33;
  }
}


.body-text--variant, .body-text--variant * {
  font-family: "ITC Serif Gothic Std";
  font-size: $paragraph-text-size--variant-mobile;
  letter-spacing: -0.01em;
  line-height: 1.2;

  @media #{$breakpoint-md-up} {
    font-size: $paragraph-text-size;
    letter-spacing: -0.025em;
    line-height: 1.33;
  }
}

.h1-text-style, .h1-text-style *,
h1, h1 * {
  font-family: "ABC Camera Plain";
  font-size: $h1-text-size--mobile;
  line-height: 1;
  color: $color-cyan;

  @media #{$breakpoint-md-up} {
    font-size: $h1-text-size;
    line-height: 0.88;
  }
}

.h2-text-style, .h2-text-style *,
h2, h2 * {
  font-family: "ABC Camera";
  font-size: $h2-text-size--mobile;
  line-height: 1;
  color: $color-green-dark;

  @media #{$breakpoint-md-up} {
    font-size: $h2-text-size;
    line-height: 0.88;
  }
}

.h3-text-style, .h3-text-style *,
h3, h3 * {
  font-family: "ABC Camera Plain";
  font-size: $h3-text-size--mobile;
  line-height: 1.15;
  color: $color-cyan;

  @media #{$breakpoint-md-up} {
    font-size: $h3-text-size;
  }
}

.h4-text-style, .h4-text-style *,
h4, h4 * {
  color: $color-green;
  font-size: $h4-text-size--mobile;
  line-height: 1.1;
  letter-spacing: -0.007em;

  @media #{$breakpoint-md-up} {
    font-size: $h4-text-size;
  }
}

.h5-text-style, .h5-text-style *,
h5, h5 * {
  font-size: $h5-text-size;
  line-height: 1.1;
}

.h6-text-style, .h6-text-style *,
h6, h6 * {
  font-size: $h6-text-size--mobile;
  line-height: 1.15;
  color: $color-green;

  @media #{$breakpoint-md-up} {
    font-size: $h6-text-size;
  }
}

.figcaption,
figcaption {
  font-size: $caption-text-size;
  color: $color-green-dark;
}

.copyright-text, .copyright-text * {
  font-size: $copyright-text-size--mobile;
  text-transform: uppercase;
  font-weight: bold;
  color: $color-green-dark;
  line-height: 1.2;

  @media #{$breakpoint-md-up} {
    font-size: $copyright-text-size;
    line-height: 1.1;
  }
}

.follow-path-text, .follow-path-text * {
  font-size: $text-path-text-size--mobile;
  text-transform: uppercase;
  color: $color-cyan;

  @media #{$breakpoint-md-up} {
    font-size: $text-path-text-size;
  }
}

.heading-balloon, .heading-balloon * {
  font-family: "ABC Camera";
  font-size: $balloon-text-size--mobile;
  color: $color-green-dark;

  @media #{$breakpoint-md-up} {
    font-size: $balloon-text-size;
  }
}

.ticker-text, .ticker-text * {
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  font-size: $ticker-text-size--mobile;
  line-height: 1;
  text-shadow: $color-yellow 0px 0px 0.4rem;

  @media #{$breakpoint-md-up} {
    letter-spacing: 0.15rem;
    font-size: $ticker-text-size;
  }

  &.text--green {
    text-shadow: $color-green 0px 0px 0.4rem;
  }

  &.text--green-dark {
    text-shadow: $color-green-dark 0px 0px 0.4rem;
  }

  &.text--blue {
    text-shadow: $color-blue 0px 0px 0.4rem;
  }

  &.text--cyan {
    text-shadow: $color-cyan 0px 0px 0.4rem;
  }
}

.ticker-text--variant, .ticker-text--variant * {
  font-family: "Relaate";
  letter-spacing: -0.25rem;
  line-height: 0.88;

  @media #{$breakpoint-md-up} {
    letter-spacing: -0.5rem;
  }
}


.body-text--floating-filters, .body-text--floating-filters * {
  font-size: calc(#{$paragraph-text-size}*0.7);
}


.text--green, .text--green * {
  color: $color-green;
}
.text--green-dark, .text--green-dark * {
  color: $color-green-dark;
}
.text--cyan, .text--cyan * {
  color: $color-cyan;
}
.text--yellow, .text--yellow * {
  color: $color-yellow;
}
.text--blue, .text--blue * {
  color: $color-blue;
}

.text--uppercase, .text--uppercase * {
  text-transform: uppercase;
}

ul:not(.list--no-style) {
  list-style-type: none;

  li {
    position: relative;
    padding-bottom: 0.3em;
    padding-left: 1.2em;

    & + li {
      border-top: 1px solid;
      padding-top: 0.5em;

      &:before {
        top: 0.3em;
      }
    }

    &:before {
      content: '';
      position: absolute;
      top: -0.2em;
      left: -0.4em;
      width: 1.6em;
      height: 1.6em;
      background: url('../img/graphics/high-dive-star.svg') no-repeat left top;
    }
  }
}


//MARGINS
h1 {
  & + h2,
  & + h3,
  & + h4,
  & + h5,
  & + h6 {
    margin-top: 0.6rem;
  }
}

h1, h2, h3, h4, h5, h6 {
  & + p {
    margin-top: 1rem;
  }
}
