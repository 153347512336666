body.page-404 {
  background-image: url('../img/photos/404-bg.gif');
  background-size: 200%;
  background-repeat: repeat;
  animation-name: background-repeat;
  animation-duration: 2s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  animation-timing-function: step-end;
}

.text-404 {
  position: absolute;
  width: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media #{$breakpoint-sm-up} {
    width: 70%;
  }

  @media #{$breakpoint-md-up} {
    width: 65%;
  }

  @media #{$breakpoint-lg-up} {
    width: 55%;
  }

  &, & * {
    color: $color-yellow;
    text-align: center;
  }
}


@keyframes background-repeat {
  0%   {
    background-size: 200%;
    background-position: left top;
  }
  25%  {
    background-size: 100%;
    background-position: center;
  }
  75%  {
    background-size: 50%;
    background-position: center;
  }
}
