.column-test {
  background-color: rgba(255,0,0,0.1);
  outline: 1px solid red;
  min-height: 100vh;
  min-height: calc(100vh - #{$container-pad--mobile}*2);

  @media #{$breakpoint-md-up} {
    min-height: calc(100vh - #{$container-pad}*2);
  }
}
