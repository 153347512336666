.the-deep-end {
  overflow-x: hidden;
  background: $color-blue;

  .footer__ticker-links {
    background: linear-gradient(180deg, $color-green-dark--alpha 0%, $color-blue--alpha 50%, $color-green-dark--alpha 100%);
  }

  .the-deep-end-logo {
    pointer-events: none;
    top: 2%;

    svg path {
      fill: $color-green-dark !important;
    }
  }
}

.the-deep-end-grid {
  opacity: 0;

  .msnry-loaded & {
    opacity: 1;
  }

  &__bg {
    position: fixed;
    inset: 0;
    background-image: url('../img/graphics/temp-bg.png');
    background-size: cover;
    background-position: center;
  }

  &__image {
    width: calc(100%/2);
    padding: 1.5rem .5rem;

    @media #{$breakpoint-md-up} {
      width: calc(100%/3);
      padding: 1.5rem;
    }

    &:nth-child(2) {
      padding-top: 5rem;
    }
  }
}

.random-obj-list {
  display: none;
}

.float-objects {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  z-index: 2;

  &__image {
    position: absolute;
    left: 0;
    right: 0;

    img {
      width: 30%;
      height: auto;
    }
  }
}

@for $i from 1 through 15 {
  $duration: randomNum(20, 25);

  .the-deep-end-grid__image:nth-child(15n + #{$i}) {
    animation-name: the-deep-grid-float-animate#{$i};
    animation-duration: (#{$duration})+s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }

  @keyframes the-deep-grid-float-animate#{$i} {
    $rotation: randomNum(-2, 2);
    $rotation2: randomNum(-2, 2);
    $rotation3: randomNum(-2, 2);
    $rotation4: randomNum(-2, 2);

    $xPos: randomNum(-0.05, 0.05);
    $xPos2: randomNum(-0.05, 0.05);
    $xPos3: randomNum(-0.05, 0.05);
    $xPos4: randomNum(-0.05, 0.05);

    $yPos: randomNum(0, 0.15);
    $yPos2: randomNum(-0.15, 0);
    $yPos3: randomNum(0, 0.15);
    $yPos4: randomNum(-0.15, 0);

    0%   { transform: rotate(0deg)                translate(0rem, 0rem); }
    25%  { transform: rotate((#{$rotation})+deg)  translate((#{$xPos})+rem, (#{$yPos})+rem); }
    50%  { transform: rotate((#{$rotation2})+deg) translate((#{$xPos2})+rem, (#{$yPos2})+rem); }
    75%  { transform: rotate((#{$rotation3})+deg) translate((#{$xPos3})+rem, (#{$yPos3})+rem); }
    100% { transform: rotate((#{$rotation4})+deg) translate((#{$xPos4})+rem, (#{$yPos4})+rem); }
  }
}


@for $i from 1 through 15 {
  $duration: randomNum(20, 25);

  .floating-animation:nth-child(15n + #{$i}) img {
    animation-name: floating-animation-#{$i};
    animation-duration: (#{$duration})+s;
    animation-iteration-count: infinite;
    animation-direction: alternate;
  }

  @keyframes floating-animation-#{$i} {
    $rotation: randomNum(-10, 10);
    $rotation2: randomNum(-10, 10);
    $rotation3: randomNum(-10, 10);
    $rotation4: randomNum(-10, 10);

    $xPos: randomNum(-0.5, 0.5);
    $xPos2: randomNum(-0.5, 0.5);
    $xPos3: randomNum(-0.5, 0.5);
    $xPos4: randomNum(-0.5, 0.5);

    $yPos: randomNum(0, 0.5);
    $yPos2: randomNum(-0.5, 0);
    $yPos3: randomNum(0, 0.5);
    $yPos4: randomNum(-0.5, 0);

    0%   { transform: rotate(0deg)                translate(0rem, 0rem); }
    25%  { transform: rotate((#{$rotation})+deg)  translate((#{$xPos})+rem, (#{$yPos})+rem); }
    50%  { transform: rotate((#{$rotation2})+deg) translate((#{$xPos2})+rem, (#{$yPos2})+rem); }
    75%  { transform: rotate((#{$rotation3})+deg) translate((#{$xPos3})+rem, (#{$yPos3})+rem); }
    100% { transform: rotate((#{$rotation4})+deg) translate((#{$xPos4})+rem, (#{$yPos4})+rem); }
  }
}
