@font-face {
  font-family: "ABC Camera";
  src: url('../fonts/ABCCamera-Black-Trial.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "ABC Camera Plain";
  src: url('../fonts/ABCCameraPlain-Black-Trial.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Relaate";
  src: url('../fonts/Relaate0.5-SerifNarrow.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "ITC Serif Gothic Std";
  src: url('../fonts/SerifGothicStd-Light.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
